import { Modal } from '@sqs/rosetta-elements';
import { CrossSmallCircle, Search } from '@sqs/rosetta-icons';
import { Box, Button } from '@sqs/rosetta-primitives';
import React, { useEffect, useState } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { useTableSort } from '../../hooks/useTableSort';
import { t } from '../../i18n';
import { Enterprise } from '../../models/Enterprise';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { ORG_MANAGER, SqspUser } from '../../models/SqspUser';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { fetchSchedulingInstances, selectAll } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { AcuityTextField } from '../common/AcuityTextField';
import { CreateAccount } from '../CreateAccount';
import { PageHeader } from '../PageHeader';
import { DeleteSchedulingInstanceModal } from './DeleteSchedulingInstanceModal';
import { SchedulingInstancesListResults } from './SchedulingInstancesListResults';

let searchTimeout: NodeJS.Timeout;

export const SchedulingInstancesList = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();
  const [showDeleteInstanceModal, setShowDeleteInstanceModal] = useState<SchedulingInstance | null>(null);
  const [searchInstanceValue, setSearchInstanceValue] = useState<string>('');
  const { tableSort, tableSortDir, updateSort } = useTableSort('instanceName');
  const [showSearch, setShowSearch] = useState(false);

  const { currentUser, enterprise, hasLoaded, schedulingInstances, pageInfo } = useAppSelector((state: RootState) => ({
    enterprise: (selectEnterprise(state.currentUser) as Enterprise),
    hasLoaded: !state.schedulingInstances.isLoading,
    schedulingInstances: selectAll(state.schedulingInstances),
    currentUser: (state.currentUser.currentUser as SqspUser),
    pageInfo: state.schedulingInstances.pageInfo
  }));

  useEffect(() => {
    if (!enterprise) {
      throw new Error('User is not connected to an Enterprise');
    }

    // This may be unnecessary because fetchSchedulingInstances attempts to load ALL instances, but if that ever changes...
    if (hasLoaded) {
      if (schedulingInstances.length < pageInfo.total) {
        dispatch(fetchSchedulingInstances(enterprise.id, { sort: tableSort, dir: tableSortDir }));
      }
    } else {
      dispatch(fetchSchedulingInstances(enterprise.id, { sort: tableSort, dir: tableSortDir }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSort, tableSortDir]);

  const updateSearchValue = (search: string) => {
    setSearchInstanceValue(search);
    clearTimeout(searchTimeout);
    if (!search) {
      clearTimeout(searchTimeout);
      dispatch(fetchSchedulingInstances(enterprise.id));
    }
    searchTimeout = setTimeout(() => {
      dispatch(fetchSchedulingInstances(enterprise.id, { search: search }));
    }, 500);
  };

  const openDeleteModal = (schedulingInstance: SchedulingInstance) => {
    setShowDeleteInstanceModal(schedulingInstance);
  };
  const closeDeleteModal = () => {
    setShowDeleteInstanceModal(null);
  };

  const mobileSearchButton = () => {
    return (
      <Button.Tertiary type="button" onClick={() => setShowSearch(!showSearch)}>
        <Search />
      </Button.Tertiary>);

  };

  const desktopActionButton = currentUser.role === ORG_MANAGER ? <CreateAccount /> : undefined;
  const mobileActions = [mobileSearchButton()];
  if (currentUser.role === ORG_MANAGER) {
    mobileActions.push(<CreateAccount />);
  }

  return (
    <Box>
      <PageHeader
        actionButton={desktopActionButton}
        mobileActions={mobileActions}
        title={t("Scheduling Instances", {}, { project: 'enterprise-dashboard' })} />

      {(!isMobile ? true : showSearch) && pageInfo.total > 0 &&
      <Box width="sizes.700" mb={2}>
          <AcuityTextField
          interiorPre={<Search />}
          inputProps={{
            name: 'instance-search',
            placeholder: t('Search', {}, { project: 'enterprise-dashboard' }),
            onChange: (v: string) => {
              updateSearchValue(v);
            },
            value: searchInstanceValue
          }}
          interiorPost={
          searchInstanceValue ?
          <Box>
                  <Button.Tertiary type="button" onClick={() => updateSearchValue('')} css={{ maxHeight: '16px' }}>
                    <CrossSmallCircle />
                  </Button.Tertiary>
                </Box> :
          null} />


        </Box>}

      <SchedulingInstancesListResults
        schedulingInstances={schedulingInstances}
        hasLoaded={hasLoaded}
        openDeleteModal={openDeleteModal}
        pageInfo={pageInfo}
        searchValue={searchInstanceValue}
        currentUser={currentUser} />

      <Modal.Transition>
        {showDeleteInstanceModal &&
        <DeleteSchedulingInstanceModal schedulingInstance={showDeleteInstanceModal} closeModal={closeDeleteModal} />}

      </Modal.Transition>
    </Box>);

};