import { Table } from '@sqs/rosetta-compositions';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { Cell, Row } from 'react-table';
import { ACTIONS_IDENTIFIER } from '../../../const/table';
import { MobileCellRenderer } from './MobileCellRenderer';
import { MobileHeaderRenderer } from './MobileHeaderRenderer';

interface MobileBodyRendererProps {
  readonly row: Row;
}

export const MobileBodyRenderer = React.forwardRef(({ row, ...props }: MobileBodyRendererProps, ref) => {
  const actionMenuCell = row.cells.filter((cell: Cell) => cell.column.id === ACTIONS_IDENTIFIER);
  const nonActionCells = row.cells.filter((cell: Cell) => cell.column.id !== ACTIONS_IDENTIFIER);

  return (
    <Table.Cards.BodyRow key={row.id} ref={ref} row={row} {...props}>
      <Flex justifyContent="space-between">
        <Box>
          {nonActionCells.map((cell, index) => {
            // @ts-expect-error
            const hideHeader = cell.column.hideHeaderInBody;

            if (hideHeader) {
              return (
                <Flex key={cell.column.id} mb={3}>
                  <MobileCellRenderer cell={cell} header />
                </Flex>
              );
            }

            return (
              <Flex
                key={cell.column.id}
                justifyContent="flex-start"
                gap={2}
                alignItems="flex-end"
                mb={index === nonActionCells.length - 1 ? 0 : 1}
              >
                <MobileHeaderRenderer cell={cell} />
                <MobileCellRenderer cell={cell} />
              </Flex>
            );
          })}
        </Box>
        {actionMenuCell.length === 1 && (
          <Box>
            <Table.Cards.BodyCell key={actionMenuCell[0].column.id} cell={actionMenuCell[0]} />
          </Box>
        )}
      </Flex>
    </Table.Cards.BodyRow>
  );
});
