import { OrgMember } from './OrgMember';
import { t } from '../i18n';
import { OrgManager } from './OrgManager';
import { Enterprise } from './Enterprise';
import { PaginatedResponse } from './PaginatedResponse';

export const ORG_MEMBER = 'ORG_MEMBER';
export const ORG_MANAGER = 'ORG_MANAGER';

export type SqspUserType = typeof ORG_MEMBER | typeof ORG_MANAGER;

export type OrgUserStatus = 'SENT' | 'ACCEPTED' | 'CANCELED' | 'EXPIRED' | null;

export interface SqspUserBaseProperties<T> {
  id: number;
  name: string;
  email: string;
  role: T;
  status: OrgUserStatus;
  dateAdded: string;
  squarespaceMemberId: string;
  inviteCode: string | null;
}

export type SqspUserBase<K, V = void> = V extends void ? SqspUserBaseProperties<K> : SqspUserBaseProperties<K> & V;

// Current User will be of type SqspUser with enterprise
export type SqspUser = (OrgMember | OrgManager) & {
  enterprise: Enterprise;
};

// Org User will be the list of users on the Users tab
export type OrgUser = OrgMember | OrgManager;

export interface OrgUserFetchResponse extends PaginatedResponse {
  data: OrgUser[];
}

export const sqspUserRoleMap = (role: SqspUserType) => {
  switch (role) {
    case ORG_MANAGER:
      return t("Org Manager", {}, { project: 'enterprise-dashboard' });
    case ORG_MEMBER:
      return t("Org Member", {}, { project: 'enterprise-dashboard' });
    default:
      return t("User", {}, { project: 'enterprise-dashboard' });
  }
};