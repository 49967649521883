/**
 * `index.tsx` is the default entry point for webpack.
 * This is where all mounting for the application occurs.
 */

// Include polyfills at the top of the entry point

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { t } from './i18n';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';

if (module.hot) {
  // Enable hot module replacement
  module.hot.accept();
}
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    // disabling StrictMode due to issues with react-scrolllock and calling componentWillUnmount
    // <React.StrictMode>
    <RouterProvider router={router} />
    // </React.StrictMode>
  );
} else {
  Sentry.captureMessage('Unable to mount root component. Please contact support support@acuityscheduling.com');
  console.error('Unable to mount root component. Please contact support support@acuityscheduling.com');
}

document.title = t("Enterprise Dashboard", null, { project: 'enterprise-dashboard' });