import { useRef } from 'react';

export const useCopyableText = () => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const copyText = () => {
    if (!textareaRef.current) {
      return;
    }
    textareaRef.current.readOnly = false;
    textareaRef.current.select();
    document.execCommand('copy');
    textareaRef.current.readOnly = true;

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  };

  return {
    textareaRef,
    copyText,
  };
};
