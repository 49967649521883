import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { applicationSettingsReducer } from './applicationSettings';
import { invitedOrgUsersReducer } from './invitedOrgUsers';
import { messagesReducer } from './messages';
import { schedulingInstancesReducer } from './schedulingInstances';
import { currentUserReducer } from './currentUser';
import { subUsersReducer } from './subUsers';
import { apiKeyReducer } from './apiKey';
import { orgUsersReducer } from './orgUsers';
import { ownerUsersReducer } from './ownerUsers';
import { selectedOwnerUsersReducer } from './selectedOwnerUsers';

export const rootReducer: Reducer = combineReducers({
  ownerUsers: ownerUsersReducer,
  schedulingInstances: schedulingInstancesReducer,
  currentUser: currentUserReducer,
  subUsers: subUsersReducer,
  orgUsers: orgUsersReducer,
  invitedOrgUsers: invitedOrgUsersReducer,
  selectedOwnerUsers: selectedOwnerUsersReducer,
  apiKey: apiKeyReducer,
  messages: messagesReducer,
  applicationSettings: applicationSettingsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
