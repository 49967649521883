import { ActivityIndicator } from '@sqs/rosetta-elements';
import React from 'react';
import { Box, Flex } from '@sqs/rosetta-primitives';
import { t } from '../../i18n';
import { useTheme } from '@sqs/rosetta-styled';
import { AcuityLoader } from './AcuityLoader';

export const LoadingSettings = () => {
  const { colors, borders, radii } = useTheme();
  return (
    <Flex
      mt={4}
      css={{
        borderRadius: radii[2],
        border: `${borders[1]} ${colors.gray['700']}`,
        width: '700px',
        padding: '60px'
      }}
      justifyContent="center"
      alignItems="center"
      aria-label={t("Loading...", {}, { project: 'enterprise-dashboard' })}>

      <AcuityLoader />
    </Flex>);

};