import { Action, ActionCreatorWithoutPayload, ActionCreatorWithPayload, ThunkDispatch } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../apis/EnterpriseApi';
import { t } from '../i18n';

export const reduxErrorHandler = (
e: any,
dispatch: ThunkDispatch<any, undefined, Action<string>>,
errorReceived: ActionCreatorWithPayload<any, any> | ActionCreatorWithoutPayload<any>,
errorMessage: string) =>
{
  const error: AxiosError<ErrorResponse> = e;
  Sentry.captureEvent(error);
  console.error(error);
  if (!error.response) {
    return dispatch(
      errorReceived(t("Something went wrong. Please refresh and try again.",
      null, { project: 'enterprise-dashboard' })
      )
    );
  }

  const { status, data } = error.response;
  if (status === 401) {
    window.location.href = `${__SCHEDULING_HOST__}/login.php`;
    return;
  }

  dispatch(errorReceived(errorMessage));

  return Promise.reject(data.error);
};