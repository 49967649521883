import { createEntityAdapter, createSlice, EntityState, SliceCaseReducers, PayloadAction } from '@reduxjs/toolkit';
import { SubUser } from '../../models/SubUser';
import { reduxErrorHandler } from '../../utils/reduxError';
import { AppThunk } from '../store';
import { listSubUsers } from '../../apis/EnterpriseApi';
import { t } from '../../i18n';

type subUsersState = {
  isLoading: boolean;
  error: null | string | Record<string, string>;
} & EntityState<SubUser>;

const subUsersAdaptor = createEntityAdapter<SubUser>();

export const subUsersSlice = createSlice<subUsersState, SliceCaseReducers<subUsersState>, 'subUsers'>({
  name: 'subUsers',
  initialState: subUsersAdaptor.getInitialState({
    isLoading: false,
    error: null
  }),
  reducers: {
    subUsersRequested(state) {
      state.isLoading = true;
      state.error = null;
    },
    subUsersReceived(state, action: PayloadAction<SubUser[]>) {
      subUsersAdaptor.upsertMany(state, action.payload);
      state.isLoading = false;
      state.error = null;
    },
    errorReceived(state, action: PayloadAction<string | Record<string, string>>) {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export const { reducer: subUsersReducer } = subUsersSlice;

export const { subUsersRequested, subUsersReceived, errorReceived } = subUsersSlice.actions;

export const fetchSubUsers =
(enterpriseId: number, parentId: number): AppThunk =>
async (dispatch) => {
  try {
    dispatch(subUsersRequested(undefined));
    const subUsers = await listSubUsers(enterpriseId, parentId);
    dispatch(subUsersReceived(subUsers));
  } catch (e: any) {
    return reduxErrorHandler(
      e,
      dispatch,
      errorReceived, t("Not authorized to view users",
      null, { project: 'enterprise-dashboard' })
    );
  }
};

export const selectByParentId = ({ entities }: subUsersState, parentId: number): SubUser[] => {
  if (!entities) {
    return [];
  }
  return Object.values(entities).filter(
    (subuser: SubUser | undefined): subuser is SubUser => subuser !== undefined && subuser.parentId === parentId
  );
};