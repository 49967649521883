import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

export interface ApplicationSettingsState {
  globalLoader: boolean;
}

/*
 * This file can/should be used for managing the global state of the application
 */
export const applicationSettingsSlice = createSlice<
ApplicationSettingsState,
SliceCaseReducers<ApplicationSettingsState>,
'applicationSettings'
>({
  name: 'applicationSettings',
  initialState: {
    globalLoader: false,
  },
  reducers: {
    setGlobalLoader(state, action) {
      state.globalLoader = action.payload;
    },
  },
});

export const { reducer: applicationSettingsReducer } = applicationSettingsSlice;

const { setGlobalLoader } = applicationSettingsSlice.actions;

export const enableGlobalLoader = (): AppThunk => {
  return (dispatch) => {
    dispatch(setGlobalLoader(true));
  };
};
export const disableGlobalLoader = (): AppThunk => {
  return (dispatch) => {
    dispatch(setGlobalLoader(false));
  };
};
