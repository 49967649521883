import { ApiKey } from '../models/ApiKey';
import { AppointmentCount } from '../models/AppointmentCount';
import { Enterprise } from '../models/Enterprise';
import { EnterpriseUser } from '../models/EnterpriseUser';
import { OwnerUser } from '../models/OwnerUser';
import { SqspUser } from '../models/SqspUser';
import { SubUser } from '../models/SubUser';
import { OWNER, SUBUSER, User, UserType } from '../models/User';
import { tsToDateString } from '../utils/time';
import axios from './axios';

interface CurrentUserResponse<T> {
  data: T;
  included: {
    id: number;
    name: string;
    createdDate: string;
    sqspOrgId: string | null;
  };
}

interface CreateUserResponse<T> {
  data: T;
}

interface ListUsersResponse<T extends User> {
  data: T[];
  included: {
    id: number;
    userId: number;
    startDate: string;
    endDate: string;
    value: number;
  }[];
}

export interface UserData {
  password: string;
  username: string;
  businessName: string;

  timezone?: string;
}

export interface ApiError {
  key: string;
  value: string;
  rule: string;
  error: string;
}

export interface UpdateUsername {
  username: string;

  currentPassword?: never;
  password?: never;
  passwordConfirmation?: never;
}

export interface UpdatePassword {
  password: string;
  passwordConfirmation: string;

  username?: never;
}

export interface ErrorResponse {
  status_code: number;
  message: string;
  error: string | Record<string, string>;
}

// Used by both Legacy and SQSP Enterprises to get currentUser
export const getCurrentUser = async (): Promise<EnterpriseUser | SqspUser> => {
  const response = await axios.get<CurrentUserResponse<EnterpriseUser | SqspUser>>(
    `${__SCHEDULING_HOST__}/api/enterprise/v1/me`,
  );

  const { data, included } = response.data;
  const resp = {
    ...data,
    enterprise: included,
  };
  return resp;
};

export const getUserFeatureFlags = async (flags: string[]) => {
  const response = await axios.post(`${__SCHEDULING_HOST__}/api/enterprise/v1/me/feature-flags`, {
    flags,
  });
  return response.data.flags;
};

// Legacy only below
export const updateCurrentUser = async (userData: UpdateUsername | UpdatePassword): Promise<EnterpriseUser> => {
  const response = await axios.patch<CurrentUserResponse<EnterpriseUser>>(
    `${__SCHEDULING_HOST__}/api/enterprise/v1/me`,
    userData,
  );

  const { data, included } = response.data;
  return {
    ...data,
    lastActive: tsToDateString(data.lastActive),
    enterprise: included,
  };
};

export const createUser = async (enterpriseId: number, userData: UserData): Promise<OwnerUser> => {
  const response = await axios.post<CreateUserResponse<OwnerUser>>(
    `${__SCHEDULING_HOST__}/api/enterprise/v1/enterprises/${enterpriseId}/users`,
    userData,
  );

  const user = {
    ...response.data.data,
    lastActive: tsToDateString(response.data.data.lastActive),
  };

  const endDate = new Date();
  const startDate = tsToDateString(new Date().setDate(endDate.getDate() - 7));

  return {
    ...user,
    subUsers: [],
    appointmentCount: {
      id: user.id,
      userId: user.id,
      startDate,
      endDate: tsToDateString(endDate),
      value: 0,
    },
  } as OwnerUser;
};

const listUsers = async <T extends User>(
  enterpriseId: number,
  appointmentCount: boolean,
  parentId?: number,
  userType?: UserType,
): Promise<T[]> => {
  const type = userType ? userType : parentId ? SUBUSER : OWNER;

  const response = await axios.get<ListUsersResponse<T>>(
    // eslint-disable-next-line max-len
    `${__SCHEDULING_HOST__}/api/enterprise/v1/enterprises/${enterpriseId}/users?type=${type}&appointmentCount=${appointmentCount}&parentId=${parentId}`,
  );

  const { data, included } = response.data;

  if (appointmentCount) {
    const appointmentCounts = included.reduce(
      (counts: Record<number, AppointmentCount>, { id, userId, startDate, endDate, value }) => {
        counts[id] = {
          id,
          userId,
          startDate: tsToDateString(startDate),
          endDate: tsToDateString(endDate),
          value,
        };

        return counts;
      },
      {},
    );

    return data.map((userResponse) => ({
      ...userResponse,
      lastActive: tsToDateString(userResponse.lastActive),
      appointmentCount: appointmentCount ? appointmentCounts[userResponse.id] : 0,
    }));
  }

  return data.map((userResponse) => ({
    ...userResponse,
    lastActive: tsToDateString(userResponse.lastActive),
  }));
};

// TODO: Can we remove this once we move entirely to SQSP Users?
export const listOwnerUsers = async (enterpriseId: number): Promise<OwnerUser[]> => {
  return listUsers<OwnerUser>(enterpriseId, false);
};

export const impersonateUser = async (enterpriseId: number, userId: number): Promise<void> =>
  await axios.post(`${__SCHEDULING_HOST__}/api/enterprise/v1/enterprises/${enterpriseId}/users/${userId}/impersonate`);

export const listSubUsers = async (enterpriseId: number, parentId: number): Promise<SubUser[]> => {
  return listUsers<SubUser>(enterpriseId, false, parentId);
};

export const getApiKey = async (enterpriseId: number): Promise<ApiKey> => {
  const response = await axios.get<ApiKey>(
    `${__SCHEDULING_HOST__}/api/enterprise/v1/enterprises/${enterpriseId}/api-key`,
  );
  return response.data;
};

export const updateApiKey = async (enterpriseId: number): Promise<ApiKey> => {
  const response = await axios.post<ApiKey>(
    `${__SCHEDULING_HOST__}/api/enterprise/v1/enterprises/${enterpriseId}/api-key`,
  );
  return response.data;
};

export const updateEnterpriseInformation = async (enterpriseId: number, name: string): Promise<Enterprise> => {
  const response = await axios.post<Enterprise>(
    `${__SCHEDULING_HOST__}/api/enterprise/v1/enterprises/${enterpriseId}/update-organization`,
    {
      name,
    },
  );
  return response.data;
};
