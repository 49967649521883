import React, { Context, ReactNode } from 'react';

export interface FeatureFlags {
  [key: string]: boolean;
}

interface FeatureFlagProviderProps {
  readonly children: ReactNode | ReactNode[];
  readonly featureFlags: FeatureFlags;
}

interface FeatureFlagContextProps {
  featureFlags: FeatureFlags;
  flagEnabled: (flag: string) => boolean;
}

export const FeatureFlagContext: Context<FeatureFlagContextProps> = React.createContext<FeatureFlagContextProps>({
  featureFlags: {},
  flagEnabled: (s: string) => false,
});

export const FeatureFlagProvider = ({ children, featureFlags }: FeatureFlagProviderProps) => {
  const flagEnabled = (flag: string) => {
    if (featureFlags[flag]) {
      return Boolean(featureFlags[flag]);
    }
    return false;
  };

  return <FeatureFlagContext.Provider value={{ featureFlags, flagEnabled }}>{children}</FeatureFlagContext.Provider>;
};
