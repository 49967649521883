export const FEATURE_FLAGS: Record<string, string> = {
  // TODO: Remove with AE-634
  ENTERPRISE_SEPARATE_INVITED_USER: 'enterprise-separate-invited-users',
  // TODO: Remove with AE-675
  ENTERPRISE_SHOW_SSO_IN_DASHBOARD: 'enterprise-show-sso-in-dashboard',
};

export const getFeatureFlags = () => {
  return Object.values(FEATURE_FLAGS);
};
