import { Dropdown, Wizard } from '@sqs/rosetta-compositions';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';

import { useTheme } from '@sqs/rosetta-styled';
import { space } from '@sqs/rosetta-tokens';
import { timezones } from '@sqs/scheduling-utils';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCopyableText } from '../../hooks/useCopyableText';
import styles from '../../hooks/useCopyableText.less';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { RootState } from '../../stores/rootReducer';
import { createSchedulingInstance } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { T, t } from '../../i18n';
import { AcuityLoader } from '../common/AcuityLoader';
import { AcuityTextFieldController } from '../common/AcuityTextFieldController';

interface CreateAccountFormData {
  businessName: string;
  username: string;
  password: string;
  timezone: string;
}

export function CreateAccountForm({ onRequestClose }: {readonly onRequestClose: () => void;}) {
  const { isMobile } = usePlatformBreakpoint();
  const dispatch = useAppDispatch();
  const { error, currentUser, isLoading } = useAppSelector((state: RootState) => ({
    error: state.schedulingInstances.error,
    currentUser: (state.currentUser.currentUser as EnterpriseUser),
    isLoading: state.currentUser.isLoading
  }));

  const [history] = useState(() => new Wizard.History());
  const [step, setStep] = useState<string>('form');
  const loginLink = `${__SCHEDULING_HOST__}/login.php`;

  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = useForm<CreateAccountFormData>({
    defaultValues: {
      businessName: '',
      username: '',
      password: '',
      timezone: ''
    }
  });

  const submitForm = handleSubmit(async (formData) => {
    setStep('loading');
    try {
      dispatch(createSchedulingInstance(currentUser.enterprise.id, formData));
      setStep('complete');
    } catch (e) {
      setStep('form');
    }
  });

  const closeModal = () => {
    reset();
    onRequestClose();
  };

  const { copyText, textareaRef } = useCopyableText();

  const theme = useTheme();

  const height = 700 + 16 * Object.entries(errors).length;

  let boxWidth = '700px';
  let boxHeight = `${height}px`;
  if (isMobile) {
    boxWidth = 'fullwidth';
    boxHeight = '100vh';
  }

  register('timezone', { required: t("Timezone required", {}, { project: 'enterprise-dashboard' }) });

  return (
    <Box tabIndex={0} width={boxWidth} height={boxHeight} maxWidth="1680px">
      <Wizard
        currentStepId={step}
        history={history}
        renderProgress={() => <></>}
        closeButtonProps={{
          onClick: closeModal
        }}>

        <Wizard.Step
          id="form"
          nextStepId="loading"
          hideProgress={true}
          nextButtonProps={{
            label: t("Create Account", null, { project: 'enterprise-dashboard' }),
            icon: '',
            variant: 'primary',
            onClick: submitForm,
            isDisabled: isLoading
          }}>

          <Wizard.Step.Content>
            <Wizard.Step.Content.Title>
              <T project="enterprise-dashboard">{"Create Account"}</T>
            </Wizard.Step.Content.Title>
            <Box mt={4}>
              <Text.Label htmlFor="businessName">
                <T project="enterprise-dashboard">{"Business Name"}</T>
              </Text.Label>
              <AcuityTextFieldController
                control={control}
                id="businessName"
                name="businessName"
                rules={{
                  required: t("Business Name is required", {}, { project: 'enterprise-dashboard' })
                }}
                helperText={t("Business Name appears on the Client Scheduling Page for this account",

                {}, {
                  project: 'enterprise-dashboard' })} />


            </Box>
            <Box mt={4}>
              <Text.Label htmlFor="username">
                <T project="enterprise-dashboard">{"Email Address"}</T>
              </Text.Label>
              <AcuityTextFieldController
                control={control}
                name="username"
                rules={{
                  required: t("Email is required", {}, { project: 'enterprise-dashboard' }),
                  pattern: {
                    message: t("Email is invalid", {}, { project: 'enterprise-dashboard' }),
                    value: /\S+@\S+\.\S+/
                  }
                }} />

            </Box>
            <Box mt={4}>
              <Text.Label htmlFor="password">
                <T project="enterprise-dashboard">{"Password"}</T>
              </Text.Label>
              <AcuityTextFieldController
                control={control}
                name="password"
                rules={{
                  required: t("Password is required", {}, { project: 'enterprise-dashboard' }),
                  pattern: {
                    message: t("Password must contain alphabetic characters and numbers or symbols",

                    {}, {
                      project: 'enterprise-dashboard' }),

                    value: /(?:[a-zA-Z]+)(?:[0-9]+)/
                  },
                  minLength: {
                    value: 6,
                    message: t("Password must be at least 6 characters long", {}, { project: 'enterprise-dashboard' })
                  }
                }} />

            </Box>
            <Box height={100} ml={-4}>
              <Text.Label mt={6} pr={22} pl={22}>
                Timezone
              </Text.Label>
              <Dropdown
                pr={22}
                pl={22}
                placeholder={t("Select a timezone", {}, { project: 'enterprise-dashboard' })}
                errors={{ message: errors.timezone ? errors.timezone.message : undefined }}
                onChange={(v: string) => setValue('timezone', v)}
                value={watch('timezone')}>

                {timezones.map((time) =>
                <Dropdown.Option key={time[0]} value={time[0]}>
                    {time[0]} ({time[1]})
                  </Dropdown.Option>
                )}
              </Dropdown>
            </Box>
          </Wizard.Step.Content>
        </Wizard.Step>
        <Wizard.Step id="loading" nextStepId="complete" hideProgress={true}>
          <Flex height="100%" justifyContent="center" alignItems="center">
            <AcuityLoader />
          </Flex>
        </Wizard.Step>
        <Wizard.Step id="complete" hideProgress={true} hideNext={true} hideNavigation={true}>
          <Wizard.Step.Content>
            <Wizard.Step.Content.Title>
              <T project="enterprise-dashboard">{"Account Created"}</T>
            </Wizard.Step.Content.Title>
            <Text.Subtitle color="gray.400" mt={2} mb={9}>
              <T project="enterprise-dashboard">{"Share the login credentials below with the account owner."}</T>
            </Text.Subtitle>
            <Box background={theme.colors.gray['900']} p={space[2]} mb={space[2]}>
              <textarea
                ref={textareaRef}
                readOnly
                value={t("A new account for {businessName} has been created.\n\nSign in to Acuity here:\n{loginLink}\n\nUsername:\n{username}\n\nPassword:\n{password}\n\nTimezone:\n{timezone}",









                {
                  businessName: watch('businessName'),
                  loginLink: loginLink,
                  username: watch('username'),
                  password: watch('password'),
                  timezone: watch('timezone')
                }, {
                  project: 'enterprise-dashboard' })}

                className={styles.selectableTextarea}
                style={{ minHeight: 220, width: '100%' }} />

            </Box>
            <Button.Tertiary onClick={copyText}>
              <T project="enterprise-dashboard">{"COPY LOGIN INFO"}</T>
            </Button.Tertiary>
          </Wizard.Step.Content>
        </Wizard.Step>
      </Wizard>
    </Box>);

}