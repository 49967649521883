import { Banner } from '@sqs/rosetta-compositions';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { ReactNode } from 'react';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';
import { T } from '../../../i18n';
import { ApiKey } from '../../../models/ApiKey';
import { EnterpriseUser } from '../../../models/EnterpriseUser';

interface ApiKeyDataProps {
  readonly openApiKeyModal: () => void;
  readonly showApiKey: () => ReactNode;
  readonly currentUser: EnterpriseUser;
  readonly apiKey: ApiKey;
}

export const ApiKeyData = ({ openApiKeyModal, apiKey, currentUser, showApiKey }: ApiKeyDataProps) => {
  const { colors, borders, radii } = useTheme();
  const { isMobile } = usePlatformBreakpoint();

  const getApiKeyWarning = () => {
    return (
      <>
        <Text.Caption>
          <T project="enterprise-dashboard">{"Keep your API key somewhere safe and never share it openly."}</T>
        </Text.Caption>
        {apiKey?.unencryptedKey &&
        <Text.Caption>
            <br />
            <strong>
              <T project="enterprise-dashboard">{"Note"}</T>
            </strong>
            :{' '}
            <T project="enterprise-dashboard">{"For your security, we\u2019ll only show the full key in this session. Once you exit this session and come back, you won\u2019t be able to see the full key anymore."}</T>



          </Text.Caption>}

      </>);

  };

  return (
    <Box
      mt={4}
      css={{
        borderRadius: radii[2],
        border: `${borders[1]} ${colors.gray['700']}`,
        width: isMobile ? '100%' : '65%',
        padding: isMobile ? '24px ' : '20px 40px 40px'
      }}>

      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}
        mb={2}>

        <div>
          <Text.Label>
            <T project="enterprise-dashboard">{"API Key"}</T>
          </Text.Label>
          {showApiKey()}
        </div>
        <Button.Danger size="large" onClick={openApiKeyModal} type="button" alignSelf={isMobile ? 'center' : ''}>
          <T project="enterprise-dashboard">{"Reset API Key"}</T>
        </Button.Danger>
      </Flex>
      <Banner layout="row" body={getApiKeyWarning()} mb={4} />

      <div>
        <Text.Label>
          <T project="enterprise-dashboard">{"Enterprise ID"}</T>
        </Text.Label>
        <Text.Body mt={1}>{currentUser.enterprise.id}</Text.Body>
      </div>
      <div>
        <Text.Label>
          <T project="enterprise-dashboard">{"Enterprise User ID"}</T>
        </Text.Label>
        <Text.Body mt={1}>{currentUser.id}</Text.Body>
      </div>
    </Box>);

};