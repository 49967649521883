import React, { isValidElement } from 'react';
import { Cell } from 'react-table';
import { Text } from '@sqs/rosetta-primitives';

interface MobileHeaderRenderer {
  readonly cell: Cell;
}

export const MobileHeaderRenderer = ({ cell }: MobileHeaderRenderer) => {
  const content = cell.column.render('Header');
  if (!isValidElement(content)) {
    return null;
  }
  return (
    <Text.Label color="gray.500" display="inline">
      {content}
    </Text.Label>
  );
};
