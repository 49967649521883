import { Flex } from '@sqs/rosetta-primitives';
import React from 'react';
import { t } from '../../i18n';

export const userImage = (name?: string, imageUrl?: string) => {
  if (imageUrl) {
    // image url
    return <>image</>;
  }
  if (name) {
    const firstLetter = name[0];
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{
          fontSize: '32px',
          backgroundColor: '#23B2C6',
          color: '#fff',
          width: '70px',
          height: '70px',
          borderRadius: '50%'
        }}>

        {firstLetter}
      </Flex>);

  }
  return (
    <img
      src={require('../../assets/acuity-scheduling-logo.svg')}
      alt={t("Acuity Scheduling Logo", {}, { project: 'enterprise-dashboard' })} />);


};