import React, { ChangeEvent, ReactNode } from 'react';
import { Control, useController } from 'react-hook-form';
import { AcuityTextField } from './AcuityTextField';

interface AcuityTextFieldControllerProps {
  readonly control: Control<any>;
  readonly helperText?: ReactNode;
  readonly id?: string;
  readonly interiorPost?: ReactNode;
  readonly interiorPre?: ReactNode;
  readonly name: string;
  readonly placeholder?: string;
  readonly rules?: object; // see https://react-hook-form.com/docs/useform/register#options
  readonly type?: string;
  readonly isDisabled?: boolean;
}

/**
 * To be used with React Hook Forms
 * @param {Control<any>} control
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | Iterable<React.ReactNode> | React.ReactPortal | boolean | undefined | null} helperText
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | Iterable<React.ReactNode> | React.ReactPortal | boolean | undefined | null} interiorPost
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | Iterable<React.ReactNode> | React.ReactPortal | boolean | undefined | null} interiorPre
 * @param {boolean} isDisabled
 * @param {string} name
 * @param {string | undefined} placeholder
 * @param {object | undefined} rules see https://react-hook-form.com/docs/useform/register#options
 * @param {string | undefined} type
 * @returns {React.JSX.Element}
 * @constructor
 */
export const AcuityTextFieldController = ({
  control,
  helperText,
  id,
  interiorPost,
  interiorPre,
  isDisabled,
  name,
  placeholder,
  rules,
  type,
}: AcuityTextFieldControllerProps): React.JSX.Element => {
  const {
    field: { disabled, ref, onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    disabled: isDisabled,
  });
  return (
    <>
      <AcuityTextField
        inputProps={{
          id,
          name,
          disabled,
          ref,
          onBlur,
          value,
          placeholder,
          type,
          onChange: (v: string, e: ChangeEvent<HTMLInputElement>) => {
            onChange(e);
          },
        }}
        helperText={helperText}
        interiorPost={interiorPost}
        interiorPre={interiorPre}
        errorText={error && <>{error.message}</>}
      />
    </>
  );
};
