import { Dropdown, Table } from '@sqs/rosetta-compositions';
import { Stack } from '@sqs/rosetta-elements';
import { AlignHorizontalLeft, AlignHorizontalRight, ArrowLeft, ArrowRight } from '@sqs/rosetta-icons';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import throttle from 'lodash/throttle';
import React, { useEffect } from 'react';
import {
  INFINITE_SCROLL_LOAD_THRESHOLD,
  PAGINATION_LIMIT_MAX,
  PAGINATION_LIMIT_MID,
  PAGINATION_LIMIT_MIN,
} from '../../../const/pagination';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';
import { NavButton } from '../NavButton';

interface TablePaginationProps {
  readonly updatePage: (pageIndex: number) => void;
  readonly updatePageSize: (pageIndex: number) => void;
}

let scrollTrack = 1;

export const TablePagination = ({ updatePage, updatePageSize }: TablePaginationProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const {
    // @ts-expect-error
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    setPageSize,
    rows,
  } = Table.useTable();

  const rowStart = pageIndex * pageSize;
  const rowEnd = rowStart + page.length;

  // if on mobile
  // add scroll listener to detect when user is X pixels from the bottom of the viewable page.
  // if they hit the threshold, increase the pageSize by Y
  // this should refresh immediately because all of the data lives in the store

  useEffect(() => {
    if (isMobile) {
      window.addEventListener(
        'scroll',
        throttle((e) => {
          const distanceToBottomOfPage = document.body.scrollHeight - window.innerHeight - window.scrollY;
          if (distanceToBottomOfPage < INFINITE_SCROLL_LOAD_THRESHOLD && canNextPage) {
            scrollTrack += 1;
            setPageSize(pageSize * scrollTrack);
          }
        }, 100),
      );
    }
  }, []);

  useEffect(() => {
    updatePage(pageIndex);
  }, [pageIndex]);

  const pageSizeUpdate = (newPageSize: number) => {
    setPageSize(newPageSize);
    updatePageSize(newPageSize);
  };

  if (isMobile) {
    // if on mobile, we're assuming infinite scroll is being used so return a padded div so the last item in the UI
    // has a little breathing room for viewing
    return <Box py={6} />;
  }

  return (
    <Flex px={6} justifyContent="end" alignItems="center" height="sizes.350">
      <Stack direction="row" space={3} alignItems="center">
        <Text.Body m={0} px={2} color="gray.300">
          Rows per Page:
        </Text.Body>
        <Dropdown onChange={pageSizeUpdate} value={pageSize} css={{ width: '80px' }} variant="floating">
          <Dropdown.Option value={PAGINATION_LIMIT_MIN}>{PAGINATION_LIMIT_MIN}</Dropdown.Option>
          <Dropdown.Option value={PAGINATION_LIMIT_MID}>{PAGINATION_LIMIT_MID}</Dropdown.Option>
          <Dropdown.Option value={PAGINATION_LIMIT_MAX}>{PAGINATION_LIMIT_MAX}</Dropdown.Option>
        </Dropdown>
        <NavButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          icon={<AlignHorizontalLeft color={!canPreviousPage ? '#CCCCCC' : 'gray.100'} />}
        />
        <NavButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          icon={<ArrowLeft color={!canPreviousPage ? '#CCCCCC' : 'gray.100'} />}
        />

        <Text.Body m={0} px={2} color="gray.300">
          {rowStart + 1}-{rowEnd} of {rows.length}
        </Text.Body>

        <NavButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
          icon={<ArrowRight color={!canNextPage ? '#CCCCCC' : 'gray.100'} />}
        />

        <NavButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          icon={<AlignHorizontalRight color={!canNextPage ? '#CCCCCC' : 'gray.100'} />}
        />
      </Stack>
    </Flex>
  );
};
