import { INITIAL_LIMIT } from '../const/pagination';
import { t } from '../i18n';
import { PageInfoQueryWithSearch, PaginatedResponse } from '../models/PaginatedResponse';
import {
  SchedulingInstance,
  SchedulingInstanceCalendarResults,
  SchedulingInstanceData,
  SchedulingInstanceFetchResponse,
  SchedulingInstanceFetchSingleResponse } from
'../models/SchedulingInstance';
import { OrgUser, OrgUserFetchResponse, SqspUser, SqspUserType } from '../models/SqspUser';
import axios from './axios';

interface ListSchedulingInstancesResponse extends PaginatedResponse {
  data: SchedulingInstance[];
}

export interface AddUserToSchedulingInstanceData {
  permissions: string;
  allowExports: boolean;
  calendars: number[];
  allCalendars: boolean;
}

export interface CreateOrgUserData {
  name: string;
  email: string;
  orgRole: string;
}

export interface ValidateSubdomainResponse {
  error?: boolean;
  message?: string;
  status?: number;
  available?: boolean;
}

export interface CreateOrgUserResponse {
  error?: boolean;
  message?: string;
  data?: OrgUser;
}

export interface SsoCredentialData {
  provider: string;
  clientId?: string;
  clientSecret?: string;
  x509Certificate?: string;
  ssoUrl: string;
}

export const listOrgUsers = async (
enterpriseId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<OrgUserFetchResponse> => {
  const url = new URL(`${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/users`);
  url.searchParams.append('offset', `${pageInfo?.offset || 0}`);
  url.searchParams.append('limit', `${pageInfo?.limit || INITIAL_LIMIT}`);
  url.searchParams.append('sort', `${pageInfo?.sort || ''}`);
  url.searchParams.append('dir', `${pageInfo?.dir || 'asc'}`);
  if (pageInfo?.search) {
    url.searchParams.append('search', `${pageInfo.search}`);
  }
  const response = await axios.get<OrgUserFetchResponse>(url.toString());
  return response.data;
};

export const listInvitedOrgUsers = async (
enterpriseId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<OrgUserFetchResponse> => {
  const url = new URL(`${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/users/invited`);
  url.searchParams.append('offset', `${pageInfo?.offset || 0}`);
  url.searchParams.append('limit', `${pageInfo?.limit || INITIAL_LIMIT}`);
  url.searchParams.append('sort', `${pageInfo?.sort || ''}`);
  url.searchParams.append('dir', `${pageInfo?.dir || 'asc'}`);
  if (pageInfo?.search) {
    url.searchParams.append('search', `${pageInfo.search}`);
  }
  const response = await axios.get<OrgUserFetchResponse>(url.toString());
  return response.data;
};

export const listSchedulingInstances = async (
enterpriseId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<SchedulingInstanceFetchResponse> => {
  const url = new URL(`${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instances`);
  url.searchParams.append('offset', `${pageInfo?.offset || 0}`);
  url.searchParams.append('limit', `${pageInfo?.limit || INITIAL_LIMIT}`);
  url.searchParams.append('sort', `${pageInfo?.sort || ''}`);
  url.searchParams.append('dir', `${pageInfo?.dir || 'asc'}`);
  if (pageInfo?.search) {
    url.searchParams.append('search', `${pageInfo.search}`);
  }

  const response = await axios.get<SchedulingInstanceFetchResponse>(url.toString());
  return response.data;
};

export const createSqspOrgUser = async (
enterpriseId: number,
userData: CreateOrgUserData)
: Promise<CreateOrgUserResponse> => {
  try {
    const response = await axios.post<CreateOrgUserResponse>(
      `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/user-invitations`,
      {
        ...userData
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject({
      error: true,
      message: t('There was an error creating the user invitation.', null, { project: 'enterprise-dashboard' })
    });
  }
};

export const fetchOrgUserData = async (enterpriseId: number, userId: string | number): Promise<OrgUser | null> => {
  const response = await axios.get(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/users/${userId}`
  );
  return response.data;
};

export const validateSubdomain = async (value: string): Promise<ValidateSubdomainResponse> => {
  const url = value.replace(/ /g, '');
  if (!url) {
    // off-chance empty responses should not prevent form submission
    return { status: 204 };
  }
  try {
    const response = await axios.get(`${__SCHEDULING_HOST__}/api/enterprise/v2/validate-subdomain/${url}`);
    const res: ValidateSubdomainResponse = response.data;
    return res;
  } catch (error) {
    return Promise.reject({
      error: true,
      message: t("An error occurred while validating the subdomain. Please try again or leave this field blank and fill out later.",

      null, {
        project: 'enterprise-dashboard' })

    });
  }
};

export const createSqspSchedulingInstance = async (
enterpriseId: number,
schedulingInstanceData: SchedulingInstanceData)
: Promise<SchedulingInstanceFetchSingleResponse> => {
  const response = await axios.post<SchedulingInstanceFetchSingleResponse>(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instances`,
    {
      ...schedulingInstanceData
    }
  );
  return response.data;
};

export const deleteSqspSchedulingInstance = async (enterpriseId: number, schedulingInstanceId: number) => {
  const response = await axios.delete<void>(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instance/${schedulingInstanceId}`
  );
  return response.data;
};

export const fetchUserSchedulingInstances = async (
enterpriseId: number,
userId: number)
: Promise<ListSchedulingInstancesResponse> => {
  const response = await axios.get(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/users/${userId}/instances`
  );
  return response.data;
};

export const fetchSchedulingInstanceCalendars = async (
enterpriseId: number,
schedulingInstanceId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<SchedulingInstanceCalendarResults> => {
  const url = new URL(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instance/${schedulingInstanceId}/calendars`
  );
  url.searchParams.append('offset', `${pageInfo?.offset || 0}`);
  url.searchParams.append('limit', `${pageInfo?.limit || INITIAL_LIMIT}`);
  if (pageInfo?.search) {
    url.searchParams.append('search', `${pageInfo.search}`);
  }

  const response = await axios.get<SchedulingInstanceCalendarResults>(url.toString());
  return response.data;
};

export const updateUserInOrg = async (enterpriseId: number, orgRole: string, orgUserId: number) => {
  const response = await axios.put(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/users/${orgUserId}`,
    {
      orgRole
    }
  );
  return response.data;
};

export const addUserToSchedulingInstance = async (
enterpriseId: number,
instanceId: number,
userId: number,
formData: AddUserToSchedulingInstanceData) =>
{
  const response = await axios.post(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instance/${instanceId}/users/${userId}`,
    {
      ...formData
    }
  );
  return response.data;
};

export const updateUserInSchedulingInstance = async (
enterpriseId: number,
instanceId: number,
userId: number,
formData: AddUserToSchedulingInstanceData) =>
{
  const response = await axios.patch(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instance/${instanceId}/users/${userId}`,
    {
      ...formData
    }
  );
  return response.data;
};

export const resendInviteToUser = async (enterpriseId: number, inviteCode: string | null) => {
  const response = await axios.post(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/user-invitations/${inviteCode}/resend`
  );
  return response.data;
};

export const cancelUserInvite = async (enterpriseId: number, inviteCode: string | null) => {
  const response = await axios.delete(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/user-invitations/${inviteCode}`
  );
  return response.data;
};

export const removeUserFromOrg = async (enterpriseId: number, userId: number) => {
  const response = await axios.delete(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/users/${userId}`
  );
  return response.data;
};

export const removeUserFromInstance = async (enterpriseId: number, instanceId: number, userId: number) => {
  const response = await axios.delete(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instance/${instanceId}/users/${userId}`
  );
  return response.data;
};

export const fetchOrGenerateUserPermissionsForSchedulingInstance = async (
enterpriseId: number,
schedulingInstanceId: number) =>
{
  const response = await axios.get(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instance/${schedulingInstanceId}/permissions/view`
  );
  return response.data;
};

export const getUserPermissionsForSchedulingInstance = async (
enterpriseId: number,
schedulingInstanceId: number,
userId: number) =>
{
  const response = await axios.get(
    `${__SCHEDULING_HOST__}/api/enterprise/v2/enterprises/${enterpriseId}/instance/${schedulingInstanceId}/users/${userId}/permissions`
  );

  return response.data;
};

// TODO: Implement actual submission for SSO Credentials
export const submitSsoCredentials = async (enterpriseId: number, ssoCredentials: SsoCredentialData) => {
  return Promise.resolve({ status: 200 });
};