import { useLocation, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '../utils/queryParams';

interface UseTableSortProps {
  initialSort: string;
}

export const useTableSort = (initialSort: string) => {
  const queryParams = useQuery();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const sort = queryParams.get('sort') || initialSort;
  const querySortDir = queryParams.get('dir') || 'asc';
  const sortDir = querySortDir === 'desc' ? querySortDir : 'asc';

  const [tableSort, setTableSort] = useState(sort);
  const [tableSortDir, setTableSortDir] = useState<'asc' | 'desc'>(sortDir);

  const updateSort = (sortValue: string, isDescending: boolean) => {
    const currentSearch = new URLSearchParams(location.search);
    const sortDirection = isDescending ? 'desc' : 'asc';
    currentSearch.set('sort', `${sortValue}`);
    currentSearch.set('dir', `${sortDirection}`);
    setTableSort(sortValue);
    setTableSortDir(sortDirection);
    setSearchParams(currentSearch);
  };

  return { tableSort, tableSortDir, searchParams, updateSort };
};
