import { Box, Button, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { Enterprise } from '../../models/Enterprise';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { deleteSqspSchedulingInstanceAction } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { T } from '../../i18n';
import { GenericModal } from '../common/GenericModal';
import { Grid, Stack } from '@sqs/rosetta-elements';
import { InfoCircle } from '@sqs/rosetta-icons';

interface DeleteSchedulingInstanceModalProps {
  readonly closeModal: () => void;
  readonly schedulingInstance: SchedulingInstance;
}

export const DeleteSchedulingInstanceModal = ({
  closeModal,
  schedulingInstance
}: DeleteSchedulingInstanceModalProps) => {
  const dispatch = useAppDispatch();

  const { currentUser, enterprise } = useAppSelector((state: RootState) => ({
    isLoading: state.currentUser.isLoading,
    currentUser: state.currentUser.currentUser,
    enterprise: (selectEnterprise(state.currentUser) as Enterprise)
  }));
  const deleteSchedulingInstance = () => {
    if (currentUser && enterprise && schedulingInstance) {
      dispatch(deleteSqspSchedulingInstanceAction(enterprise.id, schedulingInstance));
    }
    closeModal();
  };

  return (
    <GenericModal closeModal={closeModal}>
      <Grid.Container>
        <Grid.Item>
          <Box mb={2}>
            <Text.Subtitle m={0}>
              <InfoCircle sx={{ marginBottom: '-4px', marginRight: '4px', color: 'red.300' }} />
              <T project="enterprise-dashboard">{"Delete Scheduling Instance?"}</T>
            </Text.Subtitle>
          </Box>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container mb={2}>
        <Grid.Item>
          <Text.Body>
            <T project="enterprise-dashboard">{"This Scheduling Instance will permanently be deleted and cannot be undone."}</T>


          </Text.Body>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item>
          <Box height="sizes.300">
            <Stack direction="row" justifyContent="space-between" space={1}>
              <Button.Tertiary onClick={closeModal}>
                <T project="enterprise-dashboard">{"Cancel"}</T>
              </Button.Tertiary>
              <Button.Tertiary sx={{ color: 'red.300' }} onClick={() => deleteSchedulingInstance()}>
                <T project="enterprise-dashboard">{"Delete"}</T>
              </Button.Tertiary>
            </Stack>
          </Box>
        </Grid.Item>
      </Grid.Container>
    </GenericModal>);

};