import { Table } from '@sqs/rosetta-compositions';
import { ColumnOptions } from '@sqs/rosetta-compositions/build/lib/components/Table/types/ColumnOptions';
import { TextLink } from '@sqs/rosetta-elements';
import { Box, Flex } from '@sqs/rosetta-primitives';
import React, { useMemo } from 'react';
import { Cell } from 'react-table';
import { ACTIONS_IDENTIFIER } from '../../const/table';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { useSchedulingInstanceActions } from '../../hooks/useSchedulingInstanceActions';
import { useTablePagination } from '../../hooks/useTablePagination';
import { t } from '../../i18n';
import { PageInfo } from '../../models/PaginatedResponse';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { ORG_MANAGER, OrgUser } from '../../models/SqspUser';
import { getTablePaginationState } from '../../utils/pagination';
import { AcuityLoader } from '../common/AcuityLoader';
import { EmptyResults } from '../common/EmptyResults';
import { PermissionsMap } from '../common/PermissionsMap';
import { MobileBodyRenderer } from '../common/Table/MobileBodyRenderer';
import { TableDate } from '../common/Table/TableDate';
import { TableHeader, TableHeaderReactTableProps } from '../common/Table/TableHeader';
import { TablePagination } from '../common/Table/TablePagination';
import { CreateAccount } from '../CreateAccount';
import { SchedulingInstanceListActionMenu } from './SchedulingInstanceListActionMenu';

interface SchedulingInstancesListResultsProps {
  readonly schedulingInstances: SchedulingInstance[];
  readonly hasLoaded: boolean;
  readonly openDeleteModal: (schedulingInstance: SchedulingInstance) => void;
  readonly pageInfo: PageInfo;
  readonly searchValue: string;
  readonly currentUser: OrgUser;
}

export const SchedulingInstancesListResults = ({
  schedulingInstances,
  hasLoaded,
  openDeleteModal,
  pageInfo,
  searchValue,
  currentUser
}: SchedulingInstancesListResultsProps) => {
  const { updatePageSize, updatePage } = useTablePagination();
  const { isMobile } = usePlatformBreakpoint();
  const { viewSchedulingInstanceAsUser } = useSchedulingInstanceActions();

  const mobileColumnsAccessors = ['instanceName', 'calendarCount', 'userCount', 'permissions', 'id'];
  const columns = useMemo<ColumnOptions[]>(() => {
    let activeColumns: ColumnOptions[] = [
    {
      accessor: 'instanceName',
      // @ts-expect-error
      hideHeaderInBody: isMobile,
      renderCell: (cell: Cell) => {
        return (
          <TextLink
            fontSize={3}
            css={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={() => viewSchedulingInstanceAsUser((cell.row.original as SchedulingInstance))}>

              {cell.value}
            </TextLink>);

      },
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("Name", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />,


      sortType: (rowA, rowB, columnId, desc) => {
        if (rowA.values.instanceName.toLowerCase() < rowB.values.instanceName.toLowerCase()) {
          return -1;
        }
        if (rowA.values.instanceName.toLowerCase() > rowB.values.instanceName.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    },
    {
      accessor: 'calendarCount',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("# of calendars", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />


    },
    {
      accessor: 'userCount',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("# of users", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />


    },
    {
      accessor: 'permissions',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("Permissions", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />,


      tooltip: {
        children: t(
          'The permissions that you have within a Scheduling Instance.',
          {},
          { project: 'enterprise-dashboard' }
        )
      },
      disableSortBy: true,
      renderCell: (cell: Cell) => {
        return <PermissionsMap permission={cell.value} />;
      }
    },
    {
      accessor: 'dateCreated',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("Date created", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />,


      renderCell: (cell: Cell) => <TableDate cell={cell} />,
      sortType: (rowA, rowB) => {
        if (new Date(rowA.values.dateCreated).getTime() < new Date(rowB.values.dateCreated).getTime()) {
          return -1;
        }
        if (new Date(rowA.values.dateCreated).getTime() > new Date(rowB.values.dateCreated).getTime()) {
          return 1;
        }
        return 0;
      }
    },
    {
      accessor: 'id',
      id: ACTIONS_IDENTIFIER, // used for filtering out this cell during Mobile render
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) => {
        return (
          <TableHeader
            label={t("Actions", null, { project: 'enterprise-dashboard' })}
            column={column}
            toggleSortBy={toggleSortBy} />);


      },
      renderCell: (cell: Cell) =>
      <SchedulingInstanceListActionMenu
        openDeleteModal={openDeleteModal}
        schedulingInstance={(cell.row.original as SchedulingInstance)} />,


      disableSortBy: true,
      width: '75px'
    }];


    if (isMobile) {
      activeColumns = activeColumns.filter((col) => {
        if (typeof col.accessor === 'string') {
          return mobileColumnsAccessors.includes(col.accessor);
        }
      });
    }

    return activeColumns;
  }, []);

  if (!hasLoaded && !schedulingInstances.length) {
    return (
      <Flex height="300px" justifyContent="center" alignItems="center" flexDirection="column">
        <AcuityLoader />
      </Flex>);

  }

  if (searchValue && !schedulingInstances.length) {
    return (
      <EmptyResults
        title={t('There are no instances that match your search.', {}, { project: 'enterprise-dashboard' })}
        subtitle={t('Try another search.', {}, { project: 'enterprise-dashboard' })} />);


  }

  if (!schedulingInstances.length) {
    if (currentUser.role === ORG_MANAGER) {
      return (
        <EmptyResults
          title={t("You don\u2019t have any Scheduling Instances for your org yet.",

          {}, {
            project: 'enterprise-dashboard' })}

          subtitle={t("Create a Scheduling Instance to start managing your appointments.",

          {}, {
            project: 'enterprise-dashboard' })}

          actionButton={<CreateAccount showFullButton />} />);


    }

    return (
      <EmptyResults
        title={t(
          'You don’t have access to any Scheduling Instances for your org yet.',
          {},
          { project: 'enterprise-dashboard' }
        )} />);


  }

  return (
    <Box mx={!isMobile ? -6 : -3} mb={5} position="relative">
      <Table
        columns={columns}
        data={schedulingInstances}
        sortBy={{
          initialState: {
            sortBy: 'instanceName'
          }
        }}
        pagination={{
          initialState: getTablePaginationState(pageInfo)
        }}>

        {!isMobile && <Table.Grid />}
        {isMobile &&
        <Table.Cards>
            <Table.Cards.Head />
            <Table.Cards.Body BodyRowRenderer={(bodyRowProps) => <MobileBodyRenderer {...bodyRowProps} />} />
          </Table.Cards>}

        <TablePagination updatePage={updatePage} updatePageSize={updatePageSize} />
      </Table>
    </Box>);

};