import { ArrowSmallDown, ArrowUp } from '@sqs/rosetta-glyphs';
import { Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { ColumnInstance, UseSortByColumnProps } from 'react-table';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';
import { TooltipIcon } from './TooltipIcon';

export interface TableHeaderReactTableProps {
  readonly column: ColumnInstance &
  UseSortByColumnProps<object> & {
    tooltip?: string | object;
  };
  readonly toggleSortBy: (columnId: string, descending?: boolean) => void;
}

interface TableHeaderProps extends TableHeaderReactTableProps {
  readonly label: string;
}

export const TableHeader = ({ column, label, toggleSortBy }: TableHeaderProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const { tooltip } = column;

  const showTooltip = tooltip && !isMobile;

  const getSortIcon = () => {
    if (isMobile) {
      return null;
    }
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return <ArrowUp />;
      }
      return <ArrowSmallDown />;
    }
    return null;
  };

  if (!(column.id && column.canSort)) {
    return (
      <Text.Label>
        <Flex justifyContent="flex-start" alignItems="center" gap={1}>
          {label}
          {showTooltip ? <TooltipIcon column={column} /> : null}
        </Flex>
      </Text.Label>
    );
  }

  return (
    <Text.Label>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (!isMobile && column.id) {
            toggleSortBy(column.id);
          }
        }}
      >
        <Flex justifyContent="flex-start" alignItems="center" gap={1}>
          {label}
          {showTooltip ? <TooltipIcon column={column} /> : null}
          {getSortIcon()}
        </Flex>
      </a>
    </Text.Label>
  );
};
