import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { T } from '../../../i18n';
import { GenericModal } from '../../common/GenericModal';

interface ResetApiKeyModalProps {
  readonly closeModal: () => void;
  readonly resetApiKey: () => void;
}

export const ResetApiKeyModal = ({ closeModal, resetApiKey }: ResetApiKeyModalProps) => {
  return (
    <GenericModal
      closeModal={closeModal}
      modalActions={
      <Flex justifyContent="space-between" gap={1} width="100%">
          <Button.Tertiary size="medium" type="button" onClick={closeModal}>
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Primary size="medium" type="button" onClick={() => resetApiKey()}>
            <T project="enterprise-dashboard">Reset</T>
          </Button.Primary>
        </Flex>}>


      <Box height="100%">
        <Flex flexDirection="column" height="100%" alignItems="flex-start">
          <Box mb={2}>
            <Text.Subtitle m={0}>
              <T project="enterprise-dashboard">{"Generate New API Key?"}</T>
            </Text.Subtitle>
          </Box>
          <Box>
            <Text.Body>
              <T project="enterprise-dashboard">{"This cannot be undone and your current API key will stop working immediately."}</T>


            </Text.Body>
          </Box>
        </Flex>
      </Box>
    </GenericModal>);

};