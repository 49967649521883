import { useTable } from '@sqs/rosetta-compositions/build/lib/components/Table/Table';
import { Text } from '@sqs/rosetta-primitives';
import React, { isValidElement } from 'react';
import { Cell } from 'react-table';

interface MobileCellRendererProps {
  readonly cell: Cell;
  readonly header?: boolean;
}

export const MobileCellRenderer = ({ cell, header }: MobileCellRendererProps) => {
  // @ts-expect-error
  const content = cell.column.renderCell(cell, useTable());

  if (isValidElement(content)) {
    return content;
  }

  if (header) {
    return (
      <Text.Body m={0} fontWeight={500}>
        {content}
      </Text.Body>
    );
  }

  return (
    <Text.Caption color="black" fontSize={3} m={0} display={'inline'}>
      {content}
    </Text.Caption>
  );
};
