import { Box, Text } from '@sqs/rosetta-primitives';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { updateCurrentUser } from '../../stores/currentUser';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { t, T } from '../../i18n';
import { AcuityTextFieldController } from '../common/AcuityTextFieldController';

interface PasswordFormProps {
  readonly setFormIsDirty: (v: boolean) => void;
  readonly triggerReset: boolean;
  readonly setTriggerReset: (v: boolean) => void;
}

interface PasswordFormData {
  password: string;
  confirmPassword: string;
}

export const PasswordForm = React.forwardRef<HTMLFormElement, PasswordFormProps>(
  ({ setFormIsDirty, triggerReset, setTriggerReset }, ref) => {
    const dispatch = useAppDispatch();
    const {
      currentUser: { username: initialUsername }
    } = useAppSelector((state: RootState) => ({
      currentUser: (state.currentUser.currentUser as EnterpriseUser),
      error: state.currentUser.error,
      isLoading: state.currentUser.isLoading
    }));

    const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
      register,
      reset,
      resetField
    } = useForm<PasswordFormData>({
      defaultValues: {
        password: '',
        confirmPassword: ''
      }
    });
    register('confirmPassword', {
      validate: (value, formValues) =>
      value === formValues.password || t("Password confirmation does not match password",
      {}, { project: 'enterprise-dashboard' })
    });

    useEffect(() => {
      setFormIsDirty(isDirty);
    }, [isDirty]);

    useEffect(() => {
      if (triggerReset) {
        reset();
        setTriggerReset(false);
      }
    }, [triggerReset]);

    const submitPasswordForm = handleSubmit((formData) => {
      try {
        dispatch(
          updateCurrentUser(
            { password: formData.password, passwordConfirmation: formData.confirmPassword }, t("Your password was successfully changed.",
            null, { project: 'enterprise-dashboard' })
          )
        );
      } catch (err) {
        dispatch(
          sendErrorMessage(t("There was an error changing your password. Please try again.",
          null, {
            project: 'enterprise-dashboard' })

          )
        );
      }
      resetField('password');
      resetField('confirmPassword');
    });
    return (
      <form ref={ref} onSubmit={submitPasswordForm}>
        <Box mb={4}>
          <Text.Body as="label">
            <T project="enterprise-dashboard">{"New Password"}</T>
            <AcuityTextFieldController
              control={control}
              name="password"
              type="password"
              rules={{
                pattern: {
                  message: t("Password must contain alphabetic characters and numbers or symbols",

                  {}, {
                    project: 'enterprise-dashboard' }),

                  value: /(?:[a-zA-Z]+)(?:[0-9]+)/
                },
                minLength: {
                  value: 6,
                  message: t("Password must be at least 6 characters long", {}, { project: 'enterprise-dashboard' })
                }
              }} />

          </Text.Body>
        </Box>
        <Box>
          <Text.Body as="label">
            <T project="enterprise-dashboard">{"Re-Enter New Password"}</T>
            <AcuityTextFieldController control={control} name="confirmPassword" type="password" />
          </Text.Body>
        </Box>
      </form>);

  }
);