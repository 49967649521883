import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { errorListenerMiddleware } from './middleware/errorListenerMiddleware';
import { rootReducer, RootState } from './rootReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// this import is needed to fix typing fo ThunkActions when using `useAppSelector`
import type {} from 'redux-thunk/extend-redux';

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(errorListenerMiddleware.middleware),
  });
};

export const store = setupStore();

export type AppStore = ReturnType<typeof setupStore>;

// recommended to define these pieces in the redux toolkit docs:
//  https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>;

if (module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}
