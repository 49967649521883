import React, { useState } from 'react';
import { CreateAccountButton } from './CreateAccountButton';
import { CreateAccountModal } from './CreateAccountModal';
import { logAmplitude } from '../../utils/amplitude';

interface CreateAccountProps {
  readonly showFullButton?: boolean;
}

export function CreateAccount({ showFullButton = false }: CreateAccountProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CreateAccountButton
        showFullButton={showFullButton}
        onClick={() => {
          setIsOpen(true);
          logAmplitude('signup_viewed', {
            btn: 'enterprise-dashboard',
          });
        }}
      />
      <CreateAccountModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
    </>
  );
}
