import { Box } from '@sqs/rosetta-primitives';
import React, { CSSProperties } from 'react';
import { t } from '../../i18n';
import styles from './AcuityLoader.less';

export const AcuityLoader = ({ size = 'md' }: { readonly size?: 'sm' | 'md' | 'lg' }) => {
  const containerCss: CSSProperties = {};
  const dotCss: CSSProperties = {};

  let dotSize;
  switch (size) {
  case 'sm':
    dotSize = '6px';
    break;
  case 'lg':
    dotSize = '18px';
    break;
  default:
    dotSize = '11px';
    break;
  }

  containerCss.paddingLeft = dotSize;
  containerCss.marginRight = dotSize;
  dotCss.width = dotSize;
  dotCss.height = dotSize;

  return (
    <Box
      className={styles.loaderContainer}
      css={containerCss}
      aria-label={t('Loading', {}, { project: 'enterprise-dashboard' })}
    >
      <Box className={styles.loaderDot} css={dotCss} />
      <Box className={`${styles.loaderDot} ${styles.loaderSpinner}`} css={dotCss} />
    </Box>
  );
};
