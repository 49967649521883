import React from 'react';
import { Modal } from '@sqs/rosetta-elements';
import { CreateAccountForm } from './CreateAccountForm';
import { useAppSelector } from '../../stores/store';
import { RootState } from '../../stores/rootReducer';
import { isInSqspOrg } from '../../utils/sqspUserUtils';
import { CreateSchedulingInstanceModal } from './CreateSchedulingInstanceModal';

interface CreateAccountModalProps {
  readonly isOpen: boolean;
  readonly onRequestClose: () => void;
}

export function CreateAccountModal({ isOpen, onRequestClose }: CreateAccountModalProps) {
  const { currentUser } = useAppSelector((state: RootState) => ({
    currentUser: state.currentUser.currentUser,
  }));

  if (isInSqspOrg(currentUser)) {
    return <CreateSchedulingInstanceModal closeModal={onRequestClose} isOpen={isOpen} />;
  }

  return (
    <Modal.Transition>
      {isOpen && (
        <Modal onRequestClose={onRequestClose} closeOnEsc={true}>
          <Modal.Overlay />
          <Modal.Position position="center">
            <Modal.Dialog>
              <Modal.TouchScroll>
                <CreateAccountForm onRequestClose={onRequestClose} />
              </Modal.TouchScroll>
            </Modal.Dialog>
          </Modal.Position>
        </Modal>
      )}
    </Modal.Transition>
  );
}
