import { Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { Cell } from 'react-table';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';
import { tsToDateString } from '../../../utils/time';

interface TableDateProps {
  readonly cell: Cell;
}

export const TableDate = ({ cell }: TableDateProps) => {
  const { isMobile } = usePlatformBreakpoint();
  if (isMobile) {
    return <Text.Caption color="black">{tsToDateString(cell.value)}</Text.Caption>;
  }
  return <Text.Body>{tsToDateString(cell.value)}</Text.Body>;
};
