import React, { ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../stores/rootReducer';
import { useAppSelector } from '../stores/store';
import { isInSqspOrg } from '../utils/sqspUserUtils';
import { AcuityLoader } from './common/AcuityLoader';
import { FeatureFlagContext } from './FeatureFlagProvider';

interface ProtectedRouteProps {
  readonly allowedRoles: string[];
  readonly children: ReactNode;
  readonly featureFlag?: string;
  readonly sqspOnly?: boolean;
}

export const ProtectedRoute = ({ children, allowedRoles, sqspOnly, featureFlag }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { flagEnabled } = useContext(FeatureFlagContext);
  const { currentUser } = useAppSelector((state: RootState) => ({
    currentUser: state.currentUser.currentUser,
  }));

  const allowedFeatureFlag = featureFlag ? flagEnabled(featureFlag) : true;

  useEffect(() => {
    if (currentUser) {
      const isSqspUser = isInSqspOrg(currentUser);
      if (
        !allowedFeatureFlag ||
        (!isSqspUser && sqspOnly) ||
        (isSqspUser && !allowedRoles.includes(currentUser.role))
      ) {
        return navigate('/dashboard');
      }
    }
  }, [currentUser]);

  if (!currentUser) {
    return <AcuityLoader />;
  }

  return children;
};
