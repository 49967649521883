import { createSlice, PayloadAction, SliceCaseReducers, Reducer } from '@reduxjs/toolkit';
import { OwnerUser } from '../../models/OwnerUser';

export const selectedOwnerUsersSlice = createSlice<number[], SliceCaseReducers<number[]>, 'selectedOwnerUsers'>({
  name: 'selectedOwnerUsers',
  initialState: [],
  reducers: {
    ownerUserSelected(state, { payload }: PayloadAction<number>) {
      state.push(payload);
    },
    ownerUserDeselected(state, { payload }: PayloadAction<number>) {
      return state.filter((id) => id !== payload);
    },
    allOwnerUsersSelected(state, { payload }: PayloadAction<OwnerUser[]>) {
      return payload.map((ownerUser) => ownerUser.id);
    },
    allOwnerUsersDeselected() {
      return [];
    },
  },
});

export const { reducer: selectedOwnerUsersReducer } = selectedOwnerUsersSlice;

export const { ownerUserSelected, ownerUserDeselected, allOwnerUsersSelected, allOwnerUsersDeselected } =
  selectedOwnerUsersSlice.actions;
