import { PAGINATION_LIMIT_MIN } from '../const/pagination';
import { PageInfo } from '../models/PaginatedResponse';

export const convertTablePaginationToPageInfo = (page: number, pageSize: number): Partial<PageInfo> => {
  return {
    limit: pageSize,
    offset: pageSize * page,
  };
};

export const getTablePaginationState = (pageInfo: PageInfo, pageSize = PAGINATION_LIMIT_MIN) => {
  return {
    pageSize: pageSize,
    pageIndex: Math.floor(pageInfo.offset / pageInfo.total),
  };
};
