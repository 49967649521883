import { useLocation, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { PAGINATION_LIMIT_MIN } from '../const/pagination';
import { useQuery } from '../utils/queryParams';
import { usePlatformBreakpoint } from './usePlatformBreakpoint';

export const useTablePagination = () => {
  const queryParams = useQuery();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = usePlatformBreakpoint();

  const pageSize = parseInt(queryParams.get('pageSize') || `${PAGINATION_LIMIT_MIN}`, 10);
  const page = parseInt(queryParams.get('page') || '0', 10);
  const [paginationSize, setPaginationSize] = useState(pageSize);
  const updatePage = (pageIndex: number) => {
    const currentSearch = new URLSearchParams(location.search);
    currentSearch.set('page', `${pageIndex}`);
    setSearchParams(currentSearch);
  };

  const updatePageSize = (size: number) => {
    if (!isMobile) {
      const currentSearch = new URLSearchParams(location.search);
      currentSearch.set('pageSize', `${size}`);
      setSearchParams(currentSearch);
    }
    setPaginationSize(size);
  };

  return { page, paginationSize, searchParams, updatePage, updatePageSize };
};
