import { Divider, Modal } from '@sqs/rosetta-elements';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useState } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T } from '../../i18n';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { useAppSelector } from '../../stores/store';
import { LoadingSettings } from '../common/LoadingSettings';
import { OrganizationNameModal } from './OrganizationNameModal';

export const DEFAULT_ACCOUNT_MANAGER_NAME = 'Acuity Enterprise Account Manager';
export const DEFAULT_ACCOUNT_MANAGER_EMAIL = 'enterprise-am@squarespace.com';

export const OrganizationInfo = () => {
  const { isMobile } = usePlatformBreakpoint();
  const { colors, borders, radii, fontSizes } = useTheme();

  const { currentUser, isLoading, enterprise } = useAppSelector((state: RootState) => ({
    isLoading: state.currentUser.isLoading,
    currentUser: state.currentUser.currentUser,
    enterprise: selectEnterprise(state.currentUser)
  }));
  const [isNameEditModalOpen, setIsNameEditModalOpen] = useState<boolean>(false);

  if (isLoading) {
    return <LoadingSettings />;
  }

  const closeNameEditModal = () => {
    setIsNameEditModalOpen(false);
  };

  const accountManagerName = enterprise?.accountManager ? enterprise.accountManager.name : DEFAULT_ACCOUNT_MANAGER_NAME;
  const accountManagerEmail = enterprise?.accountManager ?
  enterprise.accountManager.email :
  DEFAULT_ACCOUNT_MANAGER_EMAIL;

  return (
    <Box
      mt={4}
      css={{
        borderRadius: radii[2],
        border: `${borders[1]} ${colors.gray['700']}`,
        width: isMobile ? '100%' : '65%',
        padding: isMobile ? '24px ' : '20px 40px 40px'
      }}>

      <Text.Body as="h3" fontSize={4} mt={isMobile ? 0 : 2} mb={4} fontWeight={500}>
        <T project="enterprise-dashboard">{"Organization Details"}</T>
      </Text.Body>
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        gap={2}
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}>

        <Box mb={1}>
          <Text.Label>
            <T project="enterprise-dashboard">{"Name"}</T>
          </Text.Label>
          <Text.Body mt={2} mb={0}>
            {currentUser.enterprise.name}
          </Text.Body>
        </Box>
        <Button.Secondary size="large" onClick={() => setIsNameEditModalOpen(true)} width={isMobile ? '100%' : 'auto'}>
          Edit
        </Button.Secondary>
      </Flex>
      <Divider my={4} />
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        gap={2}
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}>

        <Box mb={1}>
          <Text.Label>
            <T project="enterprise-dashboard">{"Account Manager"}</T>
          </Text.Label>
          <Text.Body mt={2} mb={0}>
            {accountManagerName}
          </Text.Body>
          <Text.Caption>{accountManagerEmail}</Text.Caption>
        </Box>
        <Button.Secondary
          size={isMobile ? 'medium' : 'large'}
          as="a"
          href={`mailto:${accountManagerEmail}`}
          target="_blank"
          rel="noopener noreferrer"
          width={isMobile ? '100%' : 'auto'}>

          <T project="enterprise-dashboard">{"Contact"}</T>
        </Button.Secondary>
      </Flex>
      <Modal.Transition>
        {isNameEditModalOpen &&
        <OrganizationNameModal closeModal={closeNameEditModal} enterprise={currentUser.enterprise} />}

      </Modal.Transition>
    </Box>);

};