import React, { ReactElement, useState } from 'react';
import { T } from '../../i18n';
import { Tabs, TabsOptionProps } from '@sqs/rosetta-elements';
import { useTheme } from '@sqs/rosetta-styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { RootState } from '../../stores/rootReducer';
import { useAppSelector } from '../../stores/store';
import styles from './TabNav.less';
import { isInSqspOrg } from '../../utils/sqspUserUtils';

export function TabNav() {
  const { fontSizes, sizes, space } = useTheme();
  const { isMobile } = usePlatformBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, isLoading } = useAppSelector((state: RootState) => ({
    isLoading: state.currentUser.isLoading,
    currentUser: state.currentUser.currentUser
  }));

  let currentTabFromLocation = location.pathname;
  if (location.pathname.indexOf('/settings') >= 0) {
    currentTabFromLocation = '/settings';
  }

  const createTabOption = (label: string | ReactElement, path: string): TabsOptionProps => {
    return {
      label,
      value: path,
      sx: {
        marginLeft: space[5],
        marginRight: space[5],
        fontSize: fontSizes[4]
      }
    };
  };

  const tabOptions: TabsOptionProps[] = [
  createTabOption(
    <span>
        <T project="enterprise-dashboard">{"Dashboard"}</T>
      </span>,
    '/dashboard'
  ),
  createTabOption(
    <span>
        <T project="enterprise-dashboard">{"Settings"}</T>
      </span>,
    '/settings'
  )];


  if (!isLoading && isInSqspOrg(currentUser)) {
    tabOptions.splice(
      1,
      0,
      createTabOption(
        <span>
          <T project="enterprise-dashboard">{"Users"}</T>
        </span>,
        '/users'
      ),
      createTabOption(
        <span>
          <T project="enterprise-dashboard">{"Reporting"}</T>
        </span>,
        '/reporting'
      )
    );
  }

  const [currentTab, setCurrentTab] = useState(
    currentTabFromLocation.length > 1 ? currentTabFromLocation : '/dashboard'
  );
  return (
    <Tabs
      className={styles.TabsContainer}
      options={tabOptions}
      value={currentTab}
      overflow={isMobile}
      onChange={(value: string) => {
        setCurrentTab(value);
        navigate(value);
      }} />);


}