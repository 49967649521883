import { Tooltip } from '@sqs/rosetta-elements';
import { QuestionMarkCircle } from '@sqs/rosetta-icons';
import { Box } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useState } from 'react';
import { ColumnInstance } from 'react-table';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';

export interface Props {
  [key: string]: any;
  readonly column: ColumnInstance & {
    tooltip?: string | object;
  };
}
export const TooltipIcon: React.FC<Props> = ({ column, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const { borders } = useTheme();
  const { isMobile } = usePlatformBreakpoint();

  const tooltipId = `tooltip-table-${column.id}`;
  const { tooltip } = column;
  if (!tooltip) {
    return null;
  }

  // Normalize tooltip props to be an object
  const tooltipProps = typeof tooltip === 'object' ? tooltip : { children: tooltip };

  const isDesktop = !isMobile;

  const onEnter = isDesktop ? () => setOpen(true) : undefined;
  const onLeave = isDesktop ? () => setOpen(false) : undefined;

  return (
    <>
      <Box
        ref={setAnchor}
        aria-describedby={tooltipId}
        aria-haspopup="true"
        onBlur={onLeave}
        onClick={isDesktop ? undefined : () => setOpen(true)}
        onFocus={onEnter}
        onMouseLeave={onLeave}
        onMouseOver={onEnter}
        sx={{
          '&:focus': {
            outline: borders[2],
            outlineColor: 'gray.100',
            outlineOffset: '2px',
            borderRadius: '50%',
          },
          '&:focus:not(:focus-visible)': {
            outline: 'none',
          },
        }}
        tabIndex="0"
        {...props}
      >
        <QuestionMarkCircle width={'16px'} color={isOpen ? 'gray.100' : 'gray.300'} display="block" />
      </Box>
      <Tooltip
        anchor={anchor}
        id={tooltipId}
        isOpen={isOpen}
        onRequestClose={isDesktop ? undefined : () => setOpen(false)}
        position="top"
        role="tooltip"
        {...tooltipProps}
      />
    </>
  );
};
