import React from 'react';
import { useAppSelector } from '../../stores/store';
import { RootState } from '../../stores/rootReducer';
import { SchedulingInstancesList } from '../SchedulingInstancesList';
import { OwnerUsersList } from '../OwnerUsersList';
import { isInSqspOrg } from '../../utils/sqspUserUtils';

export const SchedulingInstancesContainer = () => {
  const { currentUser } = useAppSelector((state: RootState) => ({
    currentUser: state.currentUser.currentUser,
  }));

  if (isInSqspOrg(currentUser)) {
    return <SchedulingInstancesList />;
  }
  return <OwnerUsersList />;
};
