import { I18nUI } from '@sqs/i18n-ui';
// @ts-expect-error
import { setI18nInstance as setRosettaCompositionsI18nInstance } from '@sqs/rosetta-compositions/setup-i18n';
// @ts-expect-error
import { setI18nInstance as setRosettaElementsI18nInstance } from '@sqs/rosetta-elements/setup-i18n';
import { setI18nInstance as setSchedulingUtilsI18nInstance } from '@sqs/scheduling-utils/lib/i18n';
import { setSchedulingEnterpriseNavigationI18NInstance } from '@sqs/scheduling-enterprise-navigation/lib/i18n';
import I18nIcuMessageFormatter from '@sqs/i18n-ui/lib/icu-message-formatter';
import I18nNumParser from '@sqs/i18n-ui/lib/number-parser';
import I18nReact from '@sqs/i18n-ui/lib/react';

const i18nInstance = new I18nUI({
  translationLocale: __TRANSLATION_LOCALE__,
  formattingLocale: __TRANSLATION_LOCALE__,
  isDebugMode: false,
});

setRosettaElementsI18nInstance(i18nInstance);
setRosettaCompositionsI18nInstance(i18nInstance);
setSchedulingUtilsI18nInstance(i18nInstance);
setSchedulingEnterpriseNavigationI18NInstance(i18nInstance);

const i18nReact = new I18nReact(i18nInstance);
const i18nIcuMessageFormatter = new I18nIcuMessageFormatter(i18nInstance);
const i18nNumParser = new I18nNumParser(i18nInstance);

export const { formatIcuMessage } = i18nIcuMessageFormatter;

export const { parseNumber } = i18nNumParser;

export const { T, Plural, FormattedCurrency, FormattedNumber, FormattedDateTime, RelativeTime } = i18nReact;

export const {
  // Vanilla Formatters
  formatNumber,
  formatCurrency,
  formatCurrencyToParts,
  getCurrencySymbol,
  formatQuantity,
  formatDateTime,
  formatRelativeTime,
  translate: t,
  pluralize,
} = i18nInstance;

export default i18nInstance;
