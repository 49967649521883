import axios from 'axios';
import Cookies from 'js-cookie';

export default axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN': Cookies.get('csrf_token'),
  },
});
