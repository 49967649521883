import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import React, { ReactElement } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';

interface PageHeaderProps {
  readonly title: string;
  readonly actionButton?: ReactElement;
  readonly mobileActions?: ReactElement[];
}

export function PageHeader({ title, actionButton, mobileActions }: PageHeaderProps) {
  const { isMobile } = usePlatformBreakpoint();
  if (isMobile) {
    return (
      <Flex justifyContent="space-between" alignItems="center" mt={6} mb={4} mx={0}>
        <Text.Subtitle m={0} fontWeight={500}>
          {title}
        </Text.Subtitle>
        <Flex justifyContent={'flex-end'} gap={2}>
          {mobileActions?.map((action) => {
            return <Box key={Math.random().toString()}>{action}</Box>;
          })}
        </Flex>
      </Flex>
    );
  }
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" my={6}>
        <Flex flexGrow={1}>
          <Text.Title m={0} fontWeight={500}>
            {title}
          </Text.Title>
        </Flex>
        {actionButton && (
          <Flex flexGrow={1} justifyContent="flex-end">
            {actionButton}
          </Flex>
        )}
      </Flex>
    </>
  );
}
