import * as Sentry from '@sentry/browser';
import React, { useContext, useState } from 'react';
import noop from 'lodash/noop';
import { Box, Text } from '@sqs/rosetta-primitives';
import { Checkbox, Modal } from '@sqs/rosetta-elements';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { DateRangePicker } from './DateRangePicker';
import { Download } from '@sqs/rosetta-icons';
import { Empty, UnixFrom, UnixRange } from '@sqs/rosetta-compositions/build/lib/components/DatePicker/DatePicker';
import { Enterprise } from '../../models/Enterprise';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { RootState } from '../../stores/rootReducer';
import { T, t } from '../../i18n';
import { Wizard } from '@sqs/rosetta-compositions';
import { exportAppointments } from '../../apis/ExportApi';
import { selectEnterprise } from '../../stores/currentUser';
import { useAppDispatch, useAppSelector } from '../../stores/store';

interface AppointmentExportModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (dateRange: Empty | UnixFrom | UnixRange, includeCanceled: boolean) => void;
  readonly setOpen?: (open: boolean) => void;
}

export const validateDateRange = (nextDateRange: Empty | UnixFrom | UnixRange): string | null => {
  if (nextDateRange.length === 0) {
    return t("Start date is required", null, { project: 'enterprise-dashboard' });
  }

  if (nextDateRange.length === 2 && nextDateRange[0] && nextDateRange[1] && nextDateRange[1] <= nextDateRange[0]) {
    return t("End date must be after the start date", null, { project: 'enterprise-dashboard' });
  }
  return null;
};

export function AppointmentExportModal({ isOpen, onClose, onSubmit }: AppointmentExportModalProps) {
  const [dateRange, setDateRange] = useState<Empty | UnixFrom | UnixRange>([]);
  const [includeCanceled, setIncludeCanceled] = useState<boolean>(false);
  const [history] = useState(() => new Wizard.History());
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { currentUser, enterprise, ownerIds } = useAppSelector((state: RootState) => ({
    currentUser: (state.currentUser.currentUser as EnterpriseUser),
    enterprise: (selectEnterprise(state.currentUser) as Enterprise),
    ownerIds: state.selectedOwnerUsers
  }));
  if (!isOpen) {
    return null;
  }

  return (
    <Modal.Transition>
      {isOpen &&
      <Modal onRequestClose={onClose}>
          <Modal.Overlay />
          <Modal.Position position="center">
            <Modal.Dialog.ResponsiveProvider>
              <Modal.Dialog>
                <Wizard
                closeButtonProps={{
                  onClick: onClose
                }}
                currentStepId="export-appointments"
                onRequestStep={noop}
                history={history}>

                  <Wizard.Step
                  id="export-appointments"
                  hideProgress
                  nextButtonProps={{
                    onClick: async () => {
                      try {
                        // TODO: Trigger API call
                        await exportAppointments(
                          enterprise.id,
                          ownerIds,
                          includeCanceled, (
                          dateRange[0] as number),
                          dateRange[1] || null
                        );
                        onSubmit(dateRange, includeCanceled);
                        onClose();
                        dispatch(
                          sendSuccessMessage(t("We will send an email to {email} when the export is complete.",


                          { email: currentUser.username }, {
                            project: 'enterprise-dashboard' })

                          )
                        );
                      } catch (err) {
                        Sentry.captureEvent((err as Error));
                        dispatch(
                          sendErrorMessage(t("Failed to export appointments. Please try again.",
                          null, {
                            project: 'enterprise-dashboard' })

                          )
                        );
                      }
                    },
                    isDisabled: dateRange.length === 0 || error !== null,
                    label: t("Export appointments", null, { project: 'enterprise-dashboard' }),
                    icon: <Download />
                  }}
                  nextStepId="end">

                    <Wizard.Step.Content>
                      <Wizard.Step.Content.Title>
                        <T project="enterprise-dashboard">{"Export to Spreadsheet (.csv)"}</T>
                      </Wizard.Step.Content.Title>
                      <Box py={2}>
                        <Text.Label>
                          <T project="enterprise-dashboard">{"Date Range"}</T>
                        </Text.Label>
                        <DateRangePicker
                        onBlur={() => setError(validateDateRange(dateRange))}
                        onChange={(nextDateRange) => {
                          setDateRange(nextDateRange);
                          setError(validateDateRange(nextDateRange));
                        }}
                        value={dateRange} />

                        <Text.Label color="red">{error}</Text.Label>
                      </Box>
                      <Box py={2}>
                        <Text.Label>
                          <T project="enterprise-dashboard">{"Options"}</T>
                        </Text.Label>
                        <Text.Body py={1} as="label" css={{ display: 'inline-flex' }}>
                          <Checkbox
                          name="include-canceled"
                          value="include-canceled"
                          checked={includeCanceled}
                          mr={2}
                          onChange={setIncludeCanceled} />

                          <T project="enterprise-dashboard">{"Include canceled appointments"}</T>
                        </Text.Body>
                      </Box>
                    </Wizard.Step.Content>
                  </Wizard.Step>
                </Wizard>
              </Modal.Dialog>
            </Modal.Dialog.ResponsiveProvider>
          </Modal.Position>
        </Modal>}

    </Modal.Transition>);

}