import { ActionList } from '@sqs/rosetta-compositions';
import { Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { T } from '../../i18n';
import { ExternalLink } from '@sqs/rosetta-glyphs';

interface ProfileDropdownProps {
  readonly name: string;
  readonly onAccountSettingsClick: () => void;
  readonly username: string;

  readonly accountManagerEmail?: string;
  readonly isInSqspOrg?: boolean;
}

export const getFirstInitial = (name: string) => name.trim()[0].toUpperCase();

export function ProfileDropdown({
  accountManagerEmail,
  name,
  onAccountSettingsClick,
  username,
  isInSqspOrg
}: ProfileDropdownProps) {
  return (
    <ActionList.PopOver
      renderTrigger={({ toggleActionListOpen }) =>
      <Flex
        alignItems="center"
        bg="black"
        borderRadius="100%"
        data-testid="popover-trigger"
        height="sizes.200"
        justifyContent="center"
        onClick={toggleActionListOpen}
        width="sizes.200"
        sx={{ cursor: 'pointer' }}
        mx={isInSqspOrg ? 2 : 0}>

          <Text.Action color="white" sx={{ fontSize: '4' }}>
            {getFirstInitial(name)}
          </Text.Action>
        </Flex>}>


      {() =>
      <Flex flexDirection="column" sx={{ backgroundColor: 'white' }}>
          <Flex flexDirection="column" data-testid="profile-dropdown-user-info">
            <Text.Subtitle px={4} m={0} pt={4} pb={1}>
              {name}
            </Text.Subtitle>
            <Text.Caption px={4} pb={4}>
              {username}
            </Text.Caption>
          </Flex>
          {isInSqspOrg ?
        <>
              <ActionList.Item
            as="a"
            target="_blank"
            href={`${__SQSP_ACCOUNT_URL__}/settings`}
            data-testid="manage-account-settings-item"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}>

                <T project="enterprise-dashboard">{"Manage Account Settings"}</T>
                &nbsp;
                <ExternalLink />
              </ActionList.Item>
              <ActionList.Item
            as="a"
            href="//help.acuityscheduling.com/"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="help-item">

                <T project="enterprise-dashboard">{"Help"}</T>
              </ActionList.Item>
            </> :

        <>
              <ActionList.Item data-testid="account-settings-item" onClick={onAccountSettingsClick}>
                <T project="enterprise-dashboard">{"Account Settings"}</T>
              </ActionList.Item>
              <ActionList.Item
            as="a"
            data-testid="contact-account-manager-item"
            href={`mailto:${accountManagerEmail || 'help@acuityscheduling.com'}`}
            target="_blank"
            rel="noopener noreferrer">

                <T project="enterprise-dashboard">{"Contact Account Manager"}</T>
              </ActionList.Item>
            </>}

          <ActionList.Item as="a" data-testid="logout-item" href={`${__SCHEDULING_HOST__}/logout.php`} pb={3}>
            <T project="enterprise-dashboard">{"Log out"}</T>
          </ActionList.Item>
        </Flex>}

    </ActionList.PopOver>);

}