import React from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { FormProvider, useForm } from 'react-hook-form';
import { selectEnterprise } from '../../stores/currentUser';
import { filterSingleUser } from '../../stores/orgUsers';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { T, t } from '../../i18n';
import { GenericModal } from '../common/GenericModal';
import { Button, Flex, Text } from '@sqs/rosetta-primitives';
import { UserRoleDropdown } from './UserRoleDropdown';
import { updateUserInOrg } from '../../apis/EnterpriseApiV2';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';

interface UserChangeRoleModalProps {
  readonly orgUserId: number;
  readonly closeModal: () => void;
  readonly refresh?: () => void;
}

interface UserRoleChangeForm {
  readonly orgRole: string;
}
export const UserChangeRoleModal = ({ orgUserId, closeModal, refresh }: UserChangeRoleModalProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();
  const { enterpriseId, orgUser } = useAppSelector((state: RootState) => ({
    enterpriseId: selectEnterprise(state.currentUser)?.id,
    orgUser: filterSingleUser(state, orgUserId)
  }));

  const formMethods = useForm<UserRoleChangeForm>({
    defaultValues: {
      orgRole: orgUser?.role ?? ''
    }
  });
  const {
    formState: { isDirty },
    reset
  } = formMethods;

  const triggerCloseModal = () => {
    closeModal();
    reset();
  };

  const submitChangeRoleForm = formMethods.handleSubmit((formData) => {
    if (enterpriseId && formData?.orgRole && formData.orgRole !== orgUser?.role) {
      updateUserInOrg(enterpriseId, formData.orgRole, orgUserId).
      then(() => {
        dispatch(sendSuccessMessage(t('User role was changed', null, { project: 'enterprise-dashboard' })));
        triggerCloseModal();
        if (refresh) {
          refresh();
        }
      }).
      catch(() => {
        dispatch(sendErrorMessage(t('Could not change user role', null, { project: 'enterprise-dashboard' })));
      });
    }
  });

  return (
    <GenericModal
      closeModal={triggerCloseModal}
      modalActions={
      <Flex justifyContent="space-between" mt={isMobile ? 0 : 6}>
          <Button.Tertiary type="button" size="medium" onClick={triggerCloseModal}>
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Primary type="submit" size="medium" onClick={submitChangeRoleForm} disabled={!isDirty}>
            <T project="enterprise-dashboard">{"Save"}</T>
          </Button.Primary>
        </Flex>}>


      <FormProvider {...formMethods}>
        <Text.Subtitle mt={0}>
          <T project="enterprise-dashboard">{"Change role"}</T>
        </Text.Subtitle>
        <Text.Caption>
          <T project="enterprise-dashboard">{"This is the user\u2019s role within the organization"}</T>
        </Text.Caption>
        <UserRoleDropdown currentRole={orgUser?.role} />
      </FormProvider>
    </GenericModal>);

};