import React, { useEffect, useMemo, useState } from 'react';
import { T, t } from '../../i18n';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { Checkbox } from '@sqs/rosetta-elements';
import { AcuityLoader } from '../common/AcuityLoader';
import { CreateAccount } from '../CreateAccount';
import { Enterprise } from '../../models/Enterprise';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import { RootState } from '../../stores/rootReducer';
import { ActionBar, Table } from '@sqs/rosetta-compositions';
import { fetchOwnerUsers, selectAll } from '../../stores/ownerUsers';
import { selectEnterprise } from '../../stores/currentUser';
import { AppointmentCount } from '../../models/AppointmentCount';
import { OwnerUser } from '../../models/OwnerUser';
import { SubUsersList } from '../SubUsersList';
import { ColumnOptions } from '@sqs/rosetta-compositions/build/lib/components/Table/types/ColumnOptions';
import { Cell } from 'react-table';
import { space } from '@sqs/rosetta-tokens';
import { ImpersonateLink } from '../common';
import {
  allOwnerUsersDeselected,
  allOwnerUsersSelected,
  ownerUserDeselected,
  ownerUserSelected } from
'../../stores/selectedOwnerUsers';
import { AppointmentExportModal } from '../AppointmentExportModal';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { PageHeader } from '../PageHeader';
import { TableHeader, TableHeaderReactTableProps } from '../common/Table/TableHeader';

interface Row {
  original?: OwnerUser;
}

const getAppointmentCount = (appointmentCount?: AppointmentCount): number =>
typeof appointmentCount !== 'undefined' ? appointmentCount.value : 0;

export function OwnerUsersList() {
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();

  const { enterprise, hasLoaded, ownerUsers, selectedOwnerUsers } = useAppSelector((state: RootState) => ({
    enterprise: (selectEnterprise(state.currentUser) as Enterprise),
    hasLoaded: !state.ownerUsers.isLoading,
    ownerUsers: selectAll(state.ownerUsers),
    selectedOwnerUsers: state.selectedOwnerUsers
  }));

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (!enterprise) {
      throw new Error('User is not connected to an Enterprise');
    }

    dispatch(fetchOwnerUsers(enterprise.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAllString = t("include all visible in table", {}, { project: 'enterprise-dashboard' });
  const deSelectAllString = t("un-select all visible in table", {}, { project: 'enterprise-dashboard' });

  const columns = useMemo<ColumnOptions[]>(
    () => [
    {
      accessor: 'id',
      Header: () =>
      !isMobile ?
      <Checkbox
        checked={allChecked && selectedOwnerUsers.length > 0}
        value={'all'}
        aria-label={allChecked ? deSelectAllString : selectAllString}
        onChange={(val: boolean) => {
          setAllChecked(val);
          if (val) {
            dispatch(allOwnerUsersSelected(ownerUsers));
          } else {
            dispatch(allOwnerUsersDeselected(null));
          }
        }} /> :


      <></>,

      renderCell: (cell: Cell) => {
        return (
          <Checkbox
            checked={selectedOwnerUsers.indexOf(cell.value) !== -1}
            aria-label={`select ${cell.row.values.businessName}`}
            onChange={(val: boolean) => {
              if (val) {
                dispatch(ownerUserSelected(cell.value));
              } else {
                dispatch(ownerUserDeselected(cell.value));
              }

              if (selectedOwnerUsers.length === 0) {
                setAllChecked(false);
              }
            }}
            value={cell.value} />);


      },
      maxWidth: 75
    },
    {
      accessor: 'businessName',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("Name", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />


    },
    {
      accessor: 'username',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("Email Address", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />,


      renderCell: (cell: Cell) =>
      <Text.Body sx={{ wordBreak: 'break-word' }}>{cell.value ? cell.value : null}</Text.Body>,


      sortType: (rowA: Row, rowB: Row) => {
        if (rowA.original?.username && rowB.original?.username) {
          if (rowA.original.username.toLowerCase() < rowB.original.username.toLowerCase()) {
            return -1;
          }
          if (rowA.original.username.toLowerCase() > rowB.original.username.toLowerCase()) {
            return 1;
          }
        }
        return 0;
      }
    },
    {
      accessor: 'subUserCount',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("Users", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />,


      maxWidth: 120,
      renderCell: (cell: Cell) => {
        const ownerUser = (cell.row.original as OwnerUser);
        return ownerUser.subUserCount ?
        <SubUsersList
          subUsersCount={ownerUser.subUserCount}
          ownerId={ownerUser.id}
          ownerEmail={ownerUser.username} /> :


        0;

      }
    },
    {
      accessor: 'lastActive',
      Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
      <TableHeader
        label={t("Last Active", null, { project: 'enterprise-dashboard' })}
        column={column}
        toggleSortBy={toggleSortBy} />,


      renderCell: (cell: Cell) => {
        return cell.value;
      },
      sortType: (rowA: Row, rowB: Row) => {
        const dateA = rowA.original ? new Date(rowA.original.lastActive).getTime() || 0 : 0;
        const dateB = rowB.original ? new Date(rowB.original.lastActive).getTime() || 0 : 0;
        return dateA === dateB ? 0 : dateA < dateB ? 1 : -1;
      },
      maxWidth: 220
    },
    {
      accessor: 'login',
      renderCell: (cell: Cell) => {
        const ownerUser = (cell.row.original as OwnerUser);
        return <ImpersonateLink enterpriseId={enterprise.id} userId={ownerUser.id} />;
      },
      Header: () =>
      <Text.Label>
            <T project="enterprise-dashboard">{"Login"}</T>
          </Text.Label>,

      maxWidth: 50,
      disableSortBy: true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [selectedOwnerUsers, ownerUsers, allChecked, deSelectAllString, selectAllString, dispatch, enterprise]
  );

  if (!hasLoaded) {
    return (
      <Flex height="100%" justifyContent="center">
        <AcuityLoader />
      </Flex>);

  }

  if (!ownerUsers.length) {
    return (
      <Box p={space[10]}>
        <Flex justifyContent="center">
          <Box mb={space[6]}>
            <Text.Subtitle>
              <T project="enterprise-dashboard">{"No Accounts Found"}</T>
            </Text.Subtitle>
          </Box>
        </Flex>
        <Flex justifyContent="center">
          <CreateAccount />
        </Flex>
      </Box>);

  }

  return (
    <>
      <PageHeader
        actionButton={<CreateAccount />}
        title={t("Scheduling Instances", {}, { project: 'enterprise-dashboard' })} />

      <Box mx={-6}>
        {' '}
        {/* negative margin needed to remove table padding which is not configurable via props */}
        <ActionBar.AutoSizing.Provider>
          <ActionBar.AutoSizing.Target>
            <Table
              columns={columns}
              data={ownerUsers}
              sortBy={{
                initialState: {
                  sortBy: 'businessName'
                }
              }}>

              {!isMobile &&
              <>
                  <Table.Grid />
                </>}

              {isMobile &&
              <>
                  <Table.Cards />
                </>}

            </Table>
          </ActionBar.AutoSizing.Target>
          <ActionBar.AnimatedContainer itemSelectedNumber={selectedOwnerUsers.length}>
            <ActionBar.AutoSizing.Container>
              <ActionBar
                actions={[
                {
                  label: t("Export To Spreadsheet", {}, { project: 'enterprise-dashboard' }),
                  onClick: () => setExportModalOpen(true),
                  preserveSelection: true
                }]}

                itemSelectedNumber={selectedOwnerUsers.length}
                maxItemsNumber={ownerUsers.length}
                onDeselectItems={() => dispatch(allOwnerUsersDeselected(null))}
                onSelectAllItems={() => dispatch(allOwnerUsersSelected(ownerUsers))} />

              <AppointmentExportModal
                isOpen={isExportModalOpen}
                onClose={() => setExportModalOpen(false)}
                onSubmit={() => dispatch(allOwnerUsersDeselected(null))} />

            </ActionBar.AutoSizing.Container>
          </ActionBar.AnimatedContainer>
        </ActionBar.AutoSizing.Provider>
      </Box>
    </>);

}