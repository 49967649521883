import { Text } from '@sqs/rosetta-primitives';
import React from 'react';
import * as Sentry from '@sentry/browser';
import { T } from './i18n';
import { Grid, TextLink } from '@sqs/rosetta-elements';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';

export default function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  Sentry.captureEvent((error as Error));
  const navigate = useNavigate();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <Grid.Container alignItems="center" gridConstraint={12}>
        <Grid.Item columns={[11, 8, 10]} m="auto" p={9}>
          <Text.Title fontSize={9} textAlign="center" pb={6}>
            <T project="enterprise-dashboard">404</T>
          </Text.Title>
          <Text.Body textAlign="center">
            <TextLink onClick={() => navigate('/dashboard')}>
              <T project="enterprise-dashboard">Click here to return to the Dashboard.</T>
            </TextLink>
          </Text.Body>
        </Grid.Item>
      </Grid.Container>);

  }

  return (
    <Grid.Container alignItems="center" gridConstraint={12}>
      <Grid.Item columns={[11, 8, 10]} m="auto" p={0}>
        <p>
          <T project="enterprise-dashboard">{"Something went wrong."}</T>
        </p>
        <p>
          <T project="enterprise-dashboard" note="Part of the phrase 'Contact {support} for assistance.'">{"Contact"}</T>


          &nbsp;
          <a href="mailto:support@acuityscheduling.com" target="_blank" rel="noopener noreferrer">
            support@acuityscheduling.com
          </a>
          &nbsp;
          <T project="enterprise-dashboard" note="Part of the phrase 'Contact {support} for assistance.'">{"for assistance."}</T>


        </p>
      </Grid.Item>
    </Grid.Container>);

}