import amplitude, { Callback } from 'amplitude-js';

if (__AMPLITUDE_API_KEY__) {
  amplitude.getInstance().init(__AMPLITUDE_API_KEY__);
}

export const logAmplitude = (event: string, data?: object, callback?: Callback) => {
  if (__AMPLITUDE_API_KEY__) {
    amplitude.getInstance().logEvent(event, data, callback);
  }
};
