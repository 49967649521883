import { Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { ReactElement } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { t } from '../../i18n';

interface EmptyResultsProps {
  readonly title: string | ReactElement;
  readonly subtitle?: string | ReactElement;
  readonly actionButton?: ReactElement;
  readonly noImage?: boolean;
}

export const EmptyResults = ({ title, subtitle, actionButton, noImage }: EmptyResultsProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const { colors, borders, radii, sizes } = useTheme();
  const showImage = !isMobile && !noImage;
  return (
    <Flex
      mt={4}
      css={{
        borderRadius: radii[2],
        border: `${borders[1]} ${colors.gray['700']}`,
        width: '100%',
        padding: isMobile ? '16px' : '40px'
      }}
      flexDirection="column"
      alignItems="center">

      {showImage &&
      <Flex justifyContent="center">
          <img
          src={require('../../assets/empty-box.svg')}
          alt={t("Empty Box", {}, { project: 'enterprise-dashboard' })} />

        </Flex>}

      <Flex justifyContent="center">
        <Text.Subtitle mb={!subtitle ? 6 : 0} textAlign="center" fontSize={isMobile ? 4 : ''}>
          {title}
        </Text.Subtitle>
      </Flex>
      {subtitle &&
      <Flex justifyContent="center" mb={6} maxWidth={'650px'} css={{ textAlign: 'center' }}>
          <Text.Body textAlign="center">{subtitle}</Text.Body>
        </Flex>}

      {actionButton &&
      <Flex justifyContent="center" width={isMobile ? '100%' : ''}>
          {actionButton}
        </Flex>}

    </Flex>);

};