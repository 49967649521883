import { createEntityAdapter, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

interface MessagesState {
  errorMessages: string[];
  successMessages: string[];
  warningMessages: string[];
}

const messagesAdapter = createEntityAdapter<MessagesState>();

export const messagesSlice = createSlice<MessagesState, SliceCaseReducers<MessagesState>, 'messages'>({
  name: 'messages',
  initialState: messagesAdapter.getInitialState({
    errorMessages: [],
    successMessages: [],
    warningMessages: [],
  }),
  reducers: {
    sendErrorMessageAction(state, action) {
      state.errorMessages.push(action.payload);
    },
    sendSuccessMessageAction(state, action) {
      state.successMessages.push(action.payload);
    },
    sendWarningMessageAction(state, action) {
      state.warningMessages.push(action.payload);
    },
    clearErrorMessagesAction(state) {
      state.errorMessages = [];
    },
    clearSuccessMessagesAction(state) {
      state.successMessages = [];
    },
    clearWarningMessagesAction(state) {
      state.warningMessages = [];
    },
    clearMessagesAction(state) {
      state.errorMessages = [];
      state.successMessages = [];
      state.warningMessages = [];
    },
  },
});

export const { reducer: messagesReducer } = messagesSlice;

const {
  sendErrorMessageAction,
  sendSuccessMessageAction,
  sendWarningMessageAction,
  clearErrorMessagesAction,
  clearSuccessMessagesAction,
  clearWarningMessagesAction,
  clearMessagesAction,
} = messagesSlice.actions;

export const sendErrorMessage = (message: string): AppThunk => {
  return async (dispatch) => {
    dispatch(sendErrorMessageAction(message));
  };
};
export const sendSuccessMessage = (message: string): AppThunk => {
  return async (dispatch) => {
    dispatch(sendSuccessMessageAction(message));
  };
};
export const sendWarningMessage = (message: string): AppThunk => {
  return async (dispatch) => {
    dispatch(sendWarningMessageAction(message));
  };
};
export const clearErrorMessages = (): AppThunk => {
  return async (dispatch) => {
    dispatch(clearErrorMessagesAction(null));
  };
};
export const clearSuccessMessages = (): AppThunk => {
  return async (dispatch) => {
    dispatch(clearSuccessMessagesAction(null));
  };
};
export const clearWarningMessages = (): AppThunk => {
  return async (dispatch) => {
    dispatch(clearWarningMessagesAction(null));
  };
};
export const clearAllMessages = (): AppThunk => {
  return async (dispatch) => {
    dispatch(clearMessagesAction(null));
  };
};
