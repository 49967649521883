import { Chip, Tabs, TabsOptionProps } from '@sqs/rosetta-elements';
import { CrossSmallCircle, Search } from '@sqs/rosetta-icons';
import { Box, Button, Flex } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { useTableSort } from '../../hooks/useTableSort';
import { T, t } from '../../i18n';
import { Enterprise } from '../../models/Enterprise';
import { SqspUser } from '../../models/SqspUser';
import { selectEnterprise } from '../../stores/currentUser';
import { fetchInvitedOrgUsers, selectAll as selectAllInvitedOrgUsers } from '../../stores/invitedOrgUsers';
import { fetchOrgUsers, selectAll as selectAllOrgUsers } from '../../stores/orgUsers';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { AcuityTextField } from '../common/AcuityTextField';
import { CreateUser } from '../CreateUser/CreateUser';
import { FeatureFlagContext } from '../FeatureFlagProvider';
import { PageHeader } from '../PageHeader';
import { UsersListResults } from './UsersListResults';

let searchTimeout: NodeJS.Timeout;

interface UsersProps {
  readonly showInvitedUsers?: boolean;
}

export const Users = ({ showInvitedUsers }: UsersProps) => {
  const { fontSizes, space } = useTheme();
  const { isMobile } = usePlatformBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tableSort, tableSortDir } = useTableSort('name');
  const [showSearch, setShowSearch] = useState(false);
  const [searchUsersValue, setSearchUsersValue] = useState('');
  const [selectedTab, setSelectedTab] = useState<string>(showInvitedUsers ? '/users/invited' : '/users');
  const { flagEnabled } = useContext(FeatureFlagContext);

  // TODO: Remove with AE-634
  const filterUsersByInviteStatus = flagEnabled(FEATURE_FLAGS.ENTERPRISE_SEPARATE_INVITED_USER);

  const { enterprise, currentUser, hasLoaded, expiredInvites, users, pageInfo } = useAppSelector((state: RootState) => {
    let usersHaveLoaded = !state.orgUsers.isLoading;
    if (filterUsersByInviteStatus) {
      usersHaveLoaded = usersHaveLoaded && !state.invitedOrgUsers.isLoading;
    }
    return {
      enterprise: (selectEnterprise(state.currentUser) as Enterprise),
      currentUser: (state.currentUser.currentUser as SqspUser),
      hasLoaded: usersHaveLoaded,
      expiredInvites: selectAllInvitedOrgUsers(state.invitedOrgUsers).filter((user) => user.status === 'EXPIRED').
      length,
      users: showInvitedUsers ? selectAllOrgUsers(state.invitedOrgUsers) : selectAllInvitedOrgUsers(state.orgUsers),
      pageInfo: showInvitedUsers ? state.invitedOrgUsers.pageInfo : state.orgUsers.pageInfo
    };
  });

  useEffect(() => {
    if (!enterprise) {
      throw new Error('User is not connected to an Enterprise');
    }
    if (filterUsersByInviteStatus) {
      dispatch(fetchInvitedOrgUsers(enterprise.id, { sort: tableSort, dir: tableSortDir }));
    }
    dispatch(fetchOrgUsers(enterprise.id, { sort: tableSort, dir: tableSortDir }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSearchValue = (search: string) => {
    setSearchUsersValue(search);
    clearTimeout(searchTimeout);
    if (!search) {
      clearTimeout(searchTimeout);
      if (showInvitedUsers) {
        dispatch(fetchInvitedOrgUsers(enterprise.id, { sort: tableSort, dir: tableSortDir }));
      } else {
        dispatch(fetchOrgUsers(enterprise.id, { sort: tableSort, dir: tableSortDir }));
      }
    }
    searchTimeout = setTimeout(() => {
      if (showInvitedUsers) {
        dispatch(fetchInvitedOrgUsers(enterprise.id, { search: search, sort: tableSort, dir: tableSortDir }));
      } else {
        dispatch(fetchOrgUsers(enterprise.id, { search: search, sort: tableSort, dir: tableSortDir }));
      }
    }, 500);
  };

  const mobileSearchButton = () => {
    return (
      <Button.Tertiary key="show-search" type="button" onClick={() => setShowSearch(!showSearch)}>
        <Search />
      </Button.Tertiary>);

  };

  // TODO: Disable this functionality if org has SSO enabled
  const tabOptions: TabsOptionProps[] = [
  {
    value: '/users',
    label: t("Active", {}, { project: 'enterprise-dashboard' }),
    sx: {
      marginRight: space[5],
      fontSize: fontSizes[4]
    }
  },
  {
    value: '/users/invited',
    label:
    <Flex>
          <T project="enterprise-dashboard">Invites</T>
          {expiredInvites > 0 ?
      <Chip usage="badge" sx={{ marginLeft: '8px' }} status="error" label={expiredInvites} /> :

      <></>}

        </Flex>,

    sx: {
      marginLeft: space[5],
      marginRight: space[5],
      fontSize: fontSizes[4]
    }
  }];


  const changeTab = (v: string) => {
    setSearchUsersValue('');
    setSelectedTab(v);
    navigate(v);
  };

  return (
    <Box position="relative">
      <PageHeader
        actionButton={<CreateUser />}
        mobileActions={[mobileSearchButton(), <CreateUser key="create-user-btn" />]}
        title={t("Users", {}, { project: 'enterprise-dashboard' })} />

      {filterUsersByInviteStatus &&
      <Box mb={7}>
          <Tabs
          htmlAttributes={{ css: { '&:before': { height: 0 } } }}
          options={tabOptions}
          value={selectedTab}
          onChange={changeTab} />

        </Box>}

      {(!isMobile ? true : showSearch) && pageInfo.total > 0 &&
      <Box width="sizes.700" mb={2}>
          <AcuityTextField
          interiorPre={<Search />}
          inputProps={{
            name: 'instance-search',
            placeholder: t('Search', {}, { project: 'enterprise-dashboard' }),
            onChange: (v: string) => {
              updateSearchValue(v);
            },
            value: searchUsersValue
          }}
          interiorPost={
          searchUsersValue ?
          <Box>
                  <Button.Tertiary type="button" onClick={() => updateSearchValue('')} css={{ maxHeight: '16px' }}>
                    <CrossSmallCircle />
                  </Button.Tertiary>
                </Box> :
          null} />


        </Box>}

      <UsersListResults
        hasLoaded={hasLoaded}
        users={users}
        currentUserId={currentUser.id}
        enterpriseId={enterprise.id}
        pageInfo={pageInfo}
        searchValue={searchUsersValue}
        invitedUsersOnly={showInvitedUsers} />

    </Box>);

};