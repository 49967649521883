import { Touchable } from '@sqs/rosetta-primitives';
import React, { ReactNode } from 'react';

interface NavButtonProps {
  readonly disabled: boolean;
  readonly onClick: () => void;
  readonly icon: ReactNode;
}

export const NavButton = ({ disabled, onClick, icon }: NavButtonProps) => (
  <Touchable.Element.Icon disabled={disabled} onClick={onClick}>
    {icon}
  </Touchable.Element.Icon>
);
