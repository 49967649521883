import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { sendErrorMessage } from '../messages';
import { errorReceived as orgMemberErrorReceived } from '../orgUsers';
import { errorReceived as ownerUsersErrorReceived } from '../ownerUsers';
import { errorReceived as schedulingInstancesErrorReceived } from '../schedulingInstances';
import { store } from '../store';
import { errorReceived as subUsersErrorReceived } from '../subUsers';

const errorListenerMiddleware = createListenerMiddleware();

/**
 * Listens for specific error actions to be called by the redux store. All of our `errorReceived` redux actions
 * should pass into the `reduxError` utility and will typically call `errorReceived` with a string as a value.
 *
 * If any new `errorReceived` actions are created and wish to surface errors via toast automatically, they must be
 * added here to ensure they are caught and surfaced.
 */
errorListenerMiddleware.startListening({
  matcher: isAnyOf(
    orgMemberErrorReceived,
    ownerUsersErrorReceived,
    schedulingInstancesErrorReceived,
    subUsersErrorReceived,
  ),
  effect: async (action, listenerApi) => {
    // declaring the type here due to Typescript not inheriting the declared by initialization of the store
    const dispatch = listenerApi.dispatch as typeof store.dispatch;

    // This ensures redux errors that pass error objects do not trigger a notification toast
    if (typeof action.payload === 'string') {
      dispatch(sendErrorMessage(action.payload));
    }
  },
});

export { errorListenerMiddleware };
