import { SearchSelectItem } from '../models/SearchSelectItem';

export const returnCheckedItemIds = (itemArray: SearchSelectItem[]): number[] => {
  return itemArray.reduce((result, item) => {
    if (item.checked) {
      result.push(item.itemId);
    }
    return result;
  }, [] as number[]);
};
