import { ActionList, Table } from '@sqs/rosetta-compositions';
import { ColumnOptions } from '@sqs/rosetta-compositions/build/lib/components/Table/types/ColumnOptions';
import { Divider, Stack, TextLink } from '@sqs/rosetta-elements';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell } from 'react-table';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { ACTIONS_IDENTIFIER } from '../../const/table';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { useTablePagination } from '../../hooks/useTablePagination';
import { t, T } from '../../i18n';
import { PageInfo } from '../../models/PaginatedResponse';
import { ORG_MEMBER, OrgUser, SqspUser, sqspUserRoleMap } from '../../models/SqspUser';
import { getTablePaginationState } from '../../utils/pagination';
import { isPendingInvitationStatus } from '../../utils/sqspUserUtils';
import { tsToDateString } from '../../utils/time';
import { AcuityLoader } from '../common/AcuityLoader';
import { EmptyResults } from '../common/EmptyResults';
import { MobileBodyRenderer } from '../common/Table/MobileBodyRenderer';
import { TableHeader, TableHeaderReactTableProps } from '../common/Table/TableHeader';
import { UsersTablePagination } from '../common/Table/UsersTablePagination';
import { UserStatusChip } from '../common/UserStatusChip';
import { CreateUser } from '../CreateUser/CreateUser';
import { FeatureFlagContext } from '../FeatureFlagProvider';
import { UserResendInvitationActionItem } from './UserResendInvitationActionItem';
import { useUserActionModals } from './useUserActionModals';

interface UsersListResultsProps {
  readonly hasLoaded: boolean;
  readonly users: OrgUser[];
  readonly currentUserId: number;
  readonly enterpriseId: number;
  readonly pageInfo: PageInfo;
  readonly searchValue: string;
  readonly invitedUsersOnly?: boolean;
}

export const UsersListResults = ({
  users,
  hasLoaded,
  currentUserId,
  enterpriseId,
  pageInfo,
  searchValue,
  invitedUsersOnly
}: UsersListResultsProps) => {
  const { updatePageSize, updatePage } = useTablePagination();
  const { isMobile } = usePlatformBreakpoint();
  const navigate = useNavigate();
  const { flagEnabled } = useContext(FeatureFlagContext);

  const { renderModals, showRemoveUserFromOrgModal, showAddToSchedulingInstanceModal, showChangeRoleModal } =
  useUserActionModals();

  // TODO: Remove with AE-634
  const filterUsersByInviteStatus = flagEnabled(FEATURE_FLAGS.ENTERPRISE_SEPARATE_INVITED_USER);

  let statusLabel = t("Status", null, { project: 'enterprise-dashboard' });
  let dateAddedTooltip = t(
    'Date when a user is active. If the invitation is still pending, this will be the date that the invitation was sent.',
    {},
    { project: 'enterprise-dashboard' }
  );
  let dateAddedLabel = t("Date Added", {}, { project: 'enterprise-dashboard' });

  if (filterUsersByInviteStatus) {
    if (invitedUsersOnly) {
      statusLabel = t('Invitation Status', null, { project: 'enterprise-dashboard' });
      dateAddedTooltip = t(
        'Date when a user was invited to your organization.',
        {},
        { project: 'enterprise-dashboard' }
      );
      dateAddedLabel = t('Date invited', {}, { project: 'enterprise-dashboard' });
    } else {
      dateAddedLabel = t('Date active', {}, { project: 'enterprise-dashboard' });
    }
  }

  const mobileColumnsAccessors = ['name', 'role', 'status', 'id'];
  let activeColumns: ColumnOptions[] = [
  {
    accessor: 'name',
    // @ts-expect-error
    hideHeaderInBody: isMobile,
    Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
    <TableHeader
      label={t("Name", null, { project: 'enterprise-dashboard' })}
      column={column}
      toggleSortBy={toggleSortBy} />,


    renderCell: (cell: Cell) => {
      const original: SqspUser = (cell.row.original as SqspUser);
      // don't link to current user's page
      if (original.id === currentUserId) {
        return (
          <Text.Body fontSize={3} m={0}>
              {cell.value}
            </Text.Body>);

      }
      return (
        <TextLink
          fontSize={3}
          css={{
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          onClick={() => navigate(`/users/view/${cell.row.values[ACTIONS_IDENTIFIER]}`)}>

            {cell.value}
          </TextLink>);

    },
    sortType: (rowA, rowB, columnId, desc) => {
      if (rowA.values.name.toLowerCase() < rowB.values.name.toLowerCase()) {
        return -1;
      }
      if (rowA.values.name.toLowerCase() > rowB.values.name.toLowerCase()) {
        return 1;
      }
      return 0;
    }
  },
  {
    accessor: 'email',
    Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
    <TableHeader
      label={t("Email Address", null, { project: 'enterprise-dashboard' })}
      column={column}
      toggleSortBy={toggleSortBy} />,


    renderCell: (cell: Cell) =>
    <Text.Body sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{cell.value}</Text.Body>

  },
  {
    accessor: 'role',
    Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
    <TableHeader
      label={t("Role", null, { project: 'enterprise-dashboard' })}
      column={column}
      toggleSortBy={toggleSortBy} />,


    tooltip: {
      css: { fontSize: 2, color: '#f00' },
      children: t("This is the user's role within the organization.", {}, { project: 'enterprise-dashboard' })
    },
    renderCell: (cell: Cell) => {
      return sqspUserRoleMap(cell.value);
    }
  },
  {
    accessor: 'status',
    Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
    <TableHeader label={statusLabel} column={column} toggleSortBy={toggleSortBy} />,

    renderCell: (cell: Cell) => {
      return <UserStatusChip status={cell.value} />;
    }
  },
  {
    accessor: 'dateAdded',
    tooltip: {
      children: dateAddedTooltip
    },
    Header: ({ column, toggleSortBy }: TableHeaderReactTableProps) =>
    <TableHeader label={dateAddedLabel} column={column} toggleSortBy={toggleSortBy} />,

    renderCell: (cell: Cell) => tsToDateString(cell.value)
  },
  {
    accessor: 'id',
    id: ACTIONS_IDENTIFIER, // used for filtering out this cell during Mobile render
    Header: t("Actions", null, { project: 'enterprise-dashboard' }),
    renderCell: (cell: Cell) => {
      const ariaLabel = t("Open User Actions Menu", null, { project: 'enterprise-dashboard' });
      const original: SqspUser = (cell.row.original as SqspUser);
      const menuItems = ({ onRequestClose }: {onRequestClose: () => void;}) =>
      <Stack direction="column" py={1} divider={isMobile && <Divider as="div" role="presentation" />}>
            {original.status === 'ACCEPTED' &&
        <ActionList.Item
          as="div"
          onClick={() => {
            navigate(`/users/view/${cell.row.values[ACTIONS_IDENTIFIER]}`);
          }}>

                <T project="enterprise-dashboard">Go to User Profile</T>
              </ActionList.Item>}

            {isPendingInvitationStatus(cell.row.values.status) ?
        <UserResendInvitationActionItem
          inviteCode={original.inviteCode}
          enterpriseId={enterpriseId}
          onRequestClose={onRequestClose} /> :


        <>
                <ActionList.Item
            as="div"
            onClick={() => {
              showChangeRoleModal(cell.value);
            }}>

                  <T project="enterprise-dashboard">{"Change role"}</T>
                </ActionList.Item>
                {cell.row.values.role === ORG_MEMBER &&
          <ActionList.Item as="div" onClick={() => showAddToSchedulingInstanceModal(cell.value)}>
                    <T project="enterprise-dashboard">{"Add user to a Scheduling Instance"}</T>
                  </ActionList.Item>}

              </>}

            <ActionList.Item as="div" onClick={() => showRemoveUserFromOrgModal(original)} color="red.400">
              {isPendingInvitationStatus(cell.row.values.status) ?
          <T project="enterprise-dashboard">Cancel invitation</T> : <T

            project="enterprise-dashboard">{"Remove user from org"}</T>}

            </ActionList.Item>
          </Stack>;


      // don't show actions for current user
      if (original.id === currentUserId) {
        return <></>;
      }

      if (isMobile) {
        return (
          <ActionList.Sheet
            aria-label={ariaLabel}
            renderHeader={() =>
            <Text.Body textAlign="center" fontWeight={500} pt={3}>
                  {cell.row.values.name}
                </Text.Body>}>


              {menuItems}
            </ActionList.Sheet>);

      }

      return (
        <ActionList.PopOver position="bottom-right" aria-label={ariaLabel}>
            {menuItems}
          </ActionList.PopOver>);

    },
    disableSortBy: true,
    maxWidth: '75px',
    align: 'center'
  }];


  // hide status when only showing active users
  if (filterUsersByInviteStatus && !invitedUsersOnly) {
    activeColumns = activeColumns.filter((col) => col.accessor !== 'status');
  }

  if (isMobile) {
    activeColumns = activeColumns.filter((col) => {
      if (typeof col.accessor === 'string') {
        return mobileColumnsAccessors.includes(col.accessor);
      }
    });
  }

  if (searchValue && !users.length) {
    return (
      <EmptyResults
        title={t(
          'There are users in your organization that match your search.',
          {},
          { project: 'enterprise-dashboard' }
        )}
        subtitle={t('Try another search.', {}, { project: 'enterprise-dashboard' })} />);


  }

  if (!hasLoaded && !users.length) {
    return (
      <Flex height="300px" justifyContent="center" alignItems="center" flexDirection="column">
        <AcuityLoader />
      </Flex>);

  }

  if (!users.length) {
    if (invitedUsersOnly) {
      return (
        <EmptyResults
          title={t('Your organization has no users pending invite.', {}, { project: 'enterprise-dashboard' })}
          actionButton={<CreateUser showFullButton />} />);


    }
    return (
      <EmptyResults
        title={t('There are no users in your organization.', {}, { project: 'enterprise-dashboard' })}
        actionButton={<CreateUser showFullButton />} />);


  }

  return (
    <Box mx={!isMobile ? -6 : -3} position="relative">
      <Table
        columns={activeColumns}
        data={users}
        sortBy={{
          initialState: {
            sortBy: {
              id: 'name'
            }
          }
        }}
        pagination={{
          initialState: getTablePaginationState(pageInfo)
        }}>

        {!isMobile && <Table.Grid />}
        {isMobile &&
        <Table.Cards>
            <Table.Cards.Head />
            <Table.Cards.Body BodyRowRenderer={(bodyRowProps) => <MobileBodyRenderer {...bodyRowProps} />} />
          </Table.Cards>}


        <UsersTablePagination updatePage={updatePage} updatePageSize={updatePageSize} />
      </Table>
      {renderModals()}
    </Box>);

};