import { Modal } from '@sqs/rosetta-elements';
import React, { useState } from 'react';
import { UserAddToSchedulingInstanceModal } from './UserAddToSchedulingInstanceModal';
import { UserChangeRoleModal } from './UserChangeRoleModal';
import { UserRemoveUserFromOrgModal } from './UserRemoveUserFromOrgModal';
import { UserRemoveUserFromSchedulingInstanceModal } from './UserRemoveUserFromSchedulingInstanceModal';
import { UserChangeUserPermissionModal } from './UserChangeUserPermissionModal';
import { OrgUser } from '../../models/SqspUser';
import { AddUserToSchedulingInstanceData } from '../../apis/EnterpriseApiV2';

export const useUserActionModals = (orgUserId?: number, onClose?: () => void, refresh?: () => void) => {
  const [showAddToSchedulingInstanceModal, setShowAddToSchedulingInstanceModal] = useState<number>(0);
  const [showRemoveUserFromOrgModal, setShowRemoveUserFromOrgModal] = useState<OrgUser>();
  const [showChangeRoleModal, setShowChangeRoleModal] = useState<number>(0);
  const [showRemoveUserFromSchedulingInstanceModal, setShowRemoveUserFromSchedulingInstanceModal] = useState<number>(0);
  const [showChangeUserPermissionModal, setShowChangeUserPermissionModal] = useState<
  AddUserToSchedulingInstanceData & { schedulingInstanceId: number }
  >();

  const closeAllModals = () => {
    if (onClose) {
      onClose();
    }
    setShowAddToSchedulingInstanceModal(0);
    setShowRemoveUserFromOrgModal(undefined);
    setShowChangeRoleModal(0);
    setShowRemoveUserFromSchedulingInstanceModal(0);
    setShowChangeUserPermissionModal(undefined);
  };

  return {
    showAddToSchedulingInstanceModal: (id?: number) => setShowAddToSchedulingInstanceModal(id || orgUserId || 0),
    showRemoveUserFromOrgModal: (orgUser?: OrgUser) => setShowRemoveUserFromOrgModal(orgUser),
    showChangeRoleModal: (id?: number) => setShowChangeRoleModal(id || orgUserId || 0),
    showRemoveUserFromSchedulingInstanceModal: (instanceId?: number) =>
      setShowRemoveUserFromSchedulingInstanceModal(instanceId || 0),
    showChangeUserPermissionModal: (instanceId: number, permissionData: AddUserToSchedulingInstanceData) =>
      setShowChangeUserPermissionModal({
        ...permissionData,
        schedulingInstanceId: instanceId,
      }),
    closeModals: closeAllModals,
    renderModals: () => (
      <Modal.Transition>
        {showAddToSchedulingInstanceModal > 0 && (
          <UserAddToSchedulingInstanceModal
            orgUserId={showAddToSchedulingInstanceModal}
            closeModal={closeAllModals}
            refresh={refresh}
          />
        )}
        {showRemoveUserFromOrgModal && (
          <UserRemoveUserFromOrgModal orgUser={showRemoveUserFromOrgModal} closeModal={closeAllModals} />
        )}
        {showRemoveUserFromSchedulingInstanceModal > 0 && orgUserId && (
          <UserRemoveUserFromSchedulingInstanceModal
            orgUserId={orgUserId}
            instanceId={showRemoveUserFromSchedulingInstanceModal}
            closeModal={closeAllModals}
            refresh={refresh}
          />
        )}
        {showChangeRoleModal > 0 && (
          <UserChangeRoleModal orgUserId={showChangeRoleModal} closeModal={closeAllModals} refresh={refresh} />
        )}
        {showChangeUserPermissionModal && orgUserId && (
          <UserChangeUserPermissionModal
            orgUserId={orgUserId}
            permissionData={showChangeUserPermissionModal}
            closeModal={closeAllModals}
            refresh={refresh}
          />
        )}
      </Modal.Transition>
    ),
  };
};
