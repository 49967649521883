import { Divider, Modal, Stack } from '@sqs/rosetta-elements';
import { CrossLarge } from '@sqs/rosetta-icons';
import { Box, Button } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { ReactElement, ReactNode } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { t } from '../../i18n';

interface GenericModalProps {
  readonly children: ReactElement | ReactElement[];
  readonly closeModal: () => void;
  readonly width?: string;
  readonly yPadding?: string | number;
  readonly xPadding?: string | number;
  readonly lockFocus?: boolean;
  readonly modalActions?: ReactNode;
}

export const GenericModal = ({
  children,
  closeModal,
  lockFocus,
  width,
  yPadding,
  xPadding,
  modalActions
}: GenericModalProps) => {
  const { space, radii, sizes } = useTheme();
  const { isMobile } = usePlatformBreakpoint();

  let boxWidth = width || '400px';
  let boxHeight;
  let contentPaddingY = yPadding || space[6];
  let contentPaddingX = xPadding || space[8];
  if (isMobile) {
    contentPaddingY = space[3];
    contentPaddingX = space[5];
    boxWidth = `calc(100vw - ${contentPaddingX} * 2)`;
    boxHeight = '100vh';
  }

  const renderModalActions = () => {
    if (modalActions) {
      if (isMobile) {
        return (
          <Box position="absolute" bottom={0} left={0} width="100%" backgroundColor="white">
            <Divider />
            <Box p={4}>{modalActions}</Box>
          </Box>);

      }
      return (
        <Box width={boxWidth} pb={contentPaddingY} backgroundColor="white">
          {modalActions}
        </Box>);

    }
    return null;
  };

  return (
    <Modal onRequestClose={closeModal} closeOnOverlayClicked={false} lockFocus={lockFocus}>
      <Modal.Overlay />
      <Modal.Position position="center">
        <Modal.Dialog borderRadius={radii[2]}>
          <Box position="relative" pt="72px" height={isMobile ? boxHeight : 'auto'}>
            <Box position="absolute" top={space[4]} right={space[6]}>
              <Button.Base onClick={closeModal} aria-label={t("Close Modal", {}, { project: 'engineering-dashboard' })}>
                <CrossLarge />
              </Button.Base>
            </Box>
            <Box tabIndex={0} height={isMobile ? 'auto' : boxHeight} px={contentPaddingX}>
              <Box width={boxWidth} maxWidth="1680px">
                {children}
              </Box>
              {renderModalActions()}
            </Box>
          </Box>
        </Modal.Dialog>
      </Modal.Position>
    </Modal>);

};