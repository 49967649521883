import { NavDialog, NavMenu } from '@sqs/rosetta-compositions';
import { Modal } from '@sqs/rosetta-elements';
import { Box, Button, Text } from '@sqs/rosetta-primitives';
import React, { useRef, useState } from 'react';
import { RootState } from '../../stores/rootReducer';
import { useAppSelector } from '../../stores/store';
import { t, T } from '../../i18n';
import { PasswordForm } from './PasswordForm';
import { UsernameForm } from './UsernameForm';

interface AccountSettingsModalProps {
  readonly isOpen: boolean;
  readonly onRequestClose: () => void;
}

const PAGE_EMAIL_ADDRESS = 'email-address';
const PAGE_PASSWORD = 'password';
type AccountSettingsPage = typeof PAGE_EMAIL_ADDRESS | typeof PAGE_PASSWORD;
export const ACCOUNT_SETTINGS_PAGES: AccountSettingsPage[] = [PAGE_EMAIL_ADDRESS, PAGE_PASSWORD];
export const TRANSLATED_PAGES = {
  [PAGE_EMAIL_ADDRESS]: t("Email Address", null, { project: 'enterprise-dashboard' }),
  [PAGE_PASSWORD]: t("Change Password", null, { project: 'enterprise-dashboard' })
};

export const AccountSettingsModal = ({ isOpen, onRequestClose }: AccountSettingsModalProps) => {
  const [currentPage, setCurrentPage] = useState<AccountSettingsPage>(PAGE_EMAIL_ADDRESS);
  const currentForm = useRef<HTMLFormElement>(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const { isLoading } = useAppSelector((state: RootState) => ({
    isLoading: state.currentUser.isLoading
  }));

  const setFormDirty = (b: boolean) => {
    setIsFormDirty(b);
  };

  const triggerResetForms = (b: boolean) => {
    setResetForm(b);
    setFormDirty(false);
  };

  return (
    <Modal.Transition>
      {isOpen &&
      <>
          <Modal
          data-testid="account-settings-modal"
          onRequestClose={() => {
            triggerResetForms(true);
            onRequestClose();
          }}>

            <Modal.Overlay />
            <Modal.Position position="center">
              <Modal.Dialog.ResponsiveProvider>
                <NavDialog page={currentPage} displayResponsiveActiveMenuItem={true}>
                  <NavDialog.Sidebar>
                    <NavDialog.Sidebar.Header>
                      <NavDialog.Sidebar.Header.Left>
                        {!isFormDirty ?
                      <Button.Tertiary onClick={onRequestClose}>
                            <T project="enterprise-dashboard">{"Close"}</T>
                          </Button.Tertiary> :

                      <>
                            <Button.Tertiary
                          onClick={() => {
                            currentForm.current?.requestSubmit();
                          }}
                          disabled={isLoading}
                          mr={2}>

                              <T project="enterprise-dashboard">{"Save"}</T>
                            </Button.Tertiary>
                            <Button.Tertiary onClick={() => triggerResetForms(true)} disabled={isLoading}>
                              <T project="enterprise-dashboard">{"Cancel"}</T>
                            </Button.Tertiary>
                          </>}

                      </NavDialog.Sidebar.Header.Left>
                    </NavDialog.Sidebar.Header>

                    <NavDialog.Sidebar.Content>
                      <NavMenu
                      onChange={(nextPage) => {
                        triggerResetForms(true);
                        setCurrentPage(nextPage);
                      }}
                      value={currentPage}
                      data-testid="nav-menu">

                        <NavMenu.NavText variant="subtitle">
                          <T project="enterprise-dashboard">{"Account Settings"}</T>
                        </NavMenu.NavText>
                        <NavMenu.NavGroup data-testid="nav-group">
                          {ACCOUNT_SETTINGS_PAGES.map((page) =>
                        <NavMenu.NavItem key={page} value={page} data-testid={`nav-item-${page}`}>
                              <NavMenu.NavText variant="body">{TRANSLATED_PAGES[page]}</NavMenu.NavText>
                            </NavMenu.NavItem>
                        )}
                        </NavMenu.NavGroup>
                      </NavMenu>
                    </NavDialog.Sidebar.Content>
                  </NavDialog.Sidebar>

                  <NavDialog.Body>
                    <Modal.TouchScroll>
                      <NavDialog.Body.Content>
                        <Text.Subtitle data-testid="nav-dialog-title" mt={0} mb={6}>
                          {TRANSLATED_PAGES[currentPage]}
                        </Text.Subtitle>
                        <Box height={400}>
                          {currentPage === PAGE_EMAIL_ADDRESS &&
                        <UsernameForm
                          ref={currentForm}
                          setFormIsDirty={setFormDirty}
                          triggerReset={resetForm}
                          setTriggerReset={triggerResetForms} />}


                          {currentPage === PAGE_PASSWORD &&
                        <PasswordForm
                          ref={currentForm}
                          setFormIsDirty={setFormDirty}
                          triggerReset={resetForm}
                          setTriggerReset={triggerResetForms} />}


                        </Box>
                      </NavDialog.Body.Content>
                    </Modal.TouchScroll>
                  </NavDialog.Body>
                </NavDialog>
              </Modal.Dialog.ResponsiveProvider>
            </Modal.Position>
          </Modal>
        </>}

    </Modal.Transition>);

};