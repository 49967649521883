import { SubUser } from './SubUser';
import { EnterpriseUser } from './EnterpriseUser';
import { t } from '../i18n';
import { OwnerUser } from './OwnerUser';

export const OWNER = 'OWNER'; // Instance Admin
export const SUBUSER = 'SUB'; // Sub User
export const ENTERPRISE = 'ENTERPRISE'; // Enterpise Org Manager

export type UserType = typeof SUBUSER | typeof OWNER | typeof ENTERPRISE;

export interface UserBaseProperties<T> {
  id: number;
  type: T;
  username: string;
  lastActive: string;
  businessName?: string;
  parentId?: number;
  subUserCount?: number;
  status?: 'active' | 'pending';
  name?: string;
  dateAdded?: string;
}

export type UserBase<K, V = void> = V extends void ? UserBaseProperties<K> : UserBaseProperties<K> & V;

export type User = SubUser | OwnerUser | EnterpriseUser;

export const userRoleMap = (role: UserType) => {
  switch (role) {
    case ENTERPRISE:
      return t("Org Manager", {}, { project: 'enterprise-dashboard' });
    case OWNER:
      return t('Owner User', {}, { project: 'enterprise-dashboard' });
    case SUBUSER:
      return t("Sub Users", {}, { project: 'enterprise-dashboard' });
    default:
      return t("User", {}, { project: 'enterprise-dashboard' });
  }
};