import { Plus } from '@sqs/rosetta-icons';
import { Button } from '@sqs/rosetta-primitives';
import React from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T } from '../../i18n';

interface CreateAccountButtonProps {
  readonly onClick: () => void;
  readonly showFullButton?: boolean;
}

export function CreateAccountButton({ onClick, showFullButton }: CreateAccountButtonProps) {
  const { isMobile } = usePlatformBreakpoint();

  if (showFullButton || !isMobile) {
    return (
      <Button.Primary onClick={onClick} size="large">
        <Plus color="white" />
        &nbsp;
        <T project="enterprise-dashboard">{"New Scheduling Instance"}</T>
      </Button.Primary>);

  }
  return (
    <Button.Tertiary onClick={onClick} size="small">
      <Plus />
    </Button.Tertiary>);

}