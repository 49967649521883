import { Breakpoint } from '@sqs/rosetta-utilities';
import { useContext } from 'react';

export const usePlatformBreakpoint = () => {
  const context = useContext(Breakpoint.Context);

  return {
    isMobile: context.platform === 'mobile',
  };
};
